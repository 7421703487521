import jQuery from "jquery";
import { UserEditableHtmlContent } from "../Lib/UserEditableHtmlContent";
import { SendEmailBase } from "../Lib/SendEmailBase";

export class SendGeneralEmail extends SendEmailBase {
    constructor() {
        super();

        // Load any additional email addresses from local storage.
        try { // localStorage may not exist or may not be enabled.
            const AdditionalAddress: string | null = localStorage.getItem('GeneralAdditionalAddresses');
            if (AdditionalAddress !== null)
                (document.getElementById('AdditionalAddresses') as HTMLInputElement).value = AdditionalAddress;
        }
        catch (e) { }

        // Save any changes to Additional Email Addresses to local storage.
        const AdditionalAddresses: HTMLInputElement = document.getElementById('AdditionalAddresses') as HTMLInputElement;
        AdditionalAddresses.addEventListener('change', () => {
            localStorage.setItem('GeneralAdditionalAddresses', AdditionalAddresses.value);
        });

        // For both send mode radio buttons, assign an event listener to update the Send button's text
        const SendModeRadioButtons = jQuery('input[name="SendEmailMode"]');
        const fHandleRBClick = (ev: Event) => {
            const rb = ev.currentTarget as HTMLInputElement;
            if (rb.checked) { // Update submit button's text to reflect currently checked radio button.
                const SubmitButton = document.getElementById('Submit') as HTMLButtonElement;
                SubmitButton.value = (rb.id === 'TestMode' ? 'Send One Test Email' : 'Send to Everyone');
            }
        };
        jQuery.each(SendModeRadioButtons, (_, rb: HTMLElement) => rb.addEventListener('change', fHandleRBClick));

        new UserEditableHtmlContent(document.getElementById("divEmailBody") as HTMLDivElement);
    }

    protected override CalcPostToURL(): string {
        return '/BTCAPI/GeneralAndGroupEmails/SendGeneralEmails';
    }

    protected DoSubmit(): void {
        // Move markup from div to hidden textarea before posting form so it can be posted.
        const textArea: HTMLTextAreaElement = document.getElementById("hiddenEmailBody") as HTMLTextAreaElement;
        const div: HTMLDivElement = document.getElementById("divEmailBody") as HTMLDivElement;
        if (textArea !== null && div !== null)
            textArea.value = div.innerHTML;

        super.DoSubmit();
    }
}
