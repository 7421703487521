import { EditRecordBase } from "../Lib/EditRecordBase";

export class MiscPaymentForm extends EditRecordBase {
    constructor() {
        super('EditRecord', '/BTCAPI/MiscPayments/MakeMiscPayment', '/BTCCheckout/', true);

        (document.getElementById("selectProdId") as HTMLSelectElement).addEventListener("change", async () => {
            await this.SetPrice();
        });

        // Prep setup functions to run in parallel because they make API server calls
        const DoSignedIn = async () => this.SignedInMemberInfo().then(async (Member) => {
            if (Member.memberId <= 0) // Not signed-in; Only not-logged in users must pass recaptcha test
                await this.SetupRecaptcha('MiscPaymentsFormRendered', 'MiscPaymentsFormSubmitted');
        })

        const DoDropDown = async () => { await this.PopulateProductsDropdown(); }

        Promise.all([DoSignedIn(), DoDropDown()])
            .then(() => { this.pPageBusyIndicator?.Decrement(); })
    }

    private async PopulateProductsDropdown(): Promise<void> {
        jQuery.get('/BTCAPI/Products/GetProducts?Active=Yes&ProdType=Misc')
            .then((prodinfo: ProdInfo[]) => {
                const select = document.getElementById("selectProdId") as HTMLSelectElement;
                prodinfo.forEach(prod => {
                    const op = document.createElement('option') as HTMLOptionElement;
                    op.value = prod.prodId.toString();
                    op.textContent = prod.prodName + " (" + prod.description + ")";
                    if (prod.price)
                        op.dataset.price = prod.price.toFixed(2);
                    select.append(op);
                }
            )})
    }

    private async SetPrice(): Promise<void> {
        const op = (document.getElementById("selectProdId") as HTMLSelectElement).selectedOptions[0];
        const PriceInput = document.getElementById("inputPaymentAmount") as HTMLInputElement;
        const PriceSpan = document.getElementById("spanPaymentAmount") as HTMLInputElement;
        if (op.dataset.price) { // Have set price for the selected product? Then hide input field and show price in span
            PriceInput.style.display = "none";
            PriceSpan.style.display = "inline";
            PriceSpan.innerText = op.dataset.price;;
        }
        else {
            PriceInput.style.display = "inline";
            PriceSpan.style.display = "none";
        }
    }
}
