import jQuery from "jquery";
import { EditRecordBase } from "../Lib/EditRecordBase";
import { ReportErrorToServer } from "../Lib/ReportErrorToServer";

export class EditMemberForm extends EditRecordBase {
    constructor(protected readonly FormId: string) {
        super(FormId, '/BTCAPI/Members/FullRecord', '/admin/members');
        this.GetDataFromServer();
    }

    private async GetDataFromServer(): Promise<void> {
        if (this.RecordId > 0) {
            const readURL: string = '/BTCAPI/Members/' + this.RecordId;
            await jQuery.get(readURL, (JsonData: {
                firstName: string, lastName: string, phoneRaw: string, gender: string,
                email: string, address: string, apt: string, city: string, state: string,
                zip: string, country: string, shirtSize: string, lastDayOfMembership: string,
                notes: string, adminAccess: string, canSendEmails: boolean
            }) => {
                (document.getElementById('FirstName') as HTMLInputElement).value = JsonData.firstName;
                (document.getElementById('LastName') as HTMLInputElement).value = JsonData.lastName;
                (document.getElementById('Phone') as HTMLInputElement).value = JsonData.phoneRaw;
                (document.getElementById('Male') as HTMLInputElement).checked = JsonData.gender === "M";
                (document.getElementById('Female') as HTMLInputElement).checked = JsonData.gender === "F";
                (document.getElementById('Email') as HTMLInputElement).value = JsonData.email;
                (document.getElementById('Address') as HTMLInputElement).value = JsonData.address;
                (document.getElementById('Apt') as HTMLInputElement).value = JsonData.apt;
                (document.getElementById('City') as HTMLInputElement).value = JsonData.city;
                (document.getElementById('State') as HTMLInputElement).value = JsonData.state;
                (document.getElementById('Zip') as HTMLInputElement).value = JsonData.zip;
                (document.getElementById('Country') as HTMLInputElement).value = JsonData.country;
                (document.getElementById('ShirtSize') as HTMLSelectElement).value = JsonData.shirtSize;
                (document.getElementById('LastDayOfMembership') as HTMLInputElement).value = JsonData.lastDayOfMembership;

                (document.getElementById('Notes') as HTMLTextAreaElement).innerText = JsonData.notes;

                (document.getElementById(JsonData.adminAccess) as HTMLInputElement).checked = true;

                (document.getElementById('CanSendEmails') as HTMLInputElement).checked = JsonData.canSendEmails;

            })
                .fail(async (jqXHR, textStatus, errorThrown) => {
                    await ReportErrorToServer.DisplayAndProcessAjaxFailure(readURL, "GET", jqXHR, textStatus, errorThrown, null, null)
                });
        }
    }
}
