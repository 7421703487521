import { EditRecordBase } from "../Lib/EditRecordBase";
import { CartCookie } from "./CartCookie";
import { PayPalWrapper } from "../Lib/PayPalWrapper";

export class CheckOutForm extends EditRecordBase {
    constructor() {
        super('formBillingInfo', null, '', false);

        this.SignedInMemberInfo().then(async (Member) => {
            if (Member.memberId <= 0) { // Not signed-in; Only not-logged in users must pass recaptcha test
                await this.SetupRecaptcha('CheckoutFormRendered', 'CheckoutFormSubmitted');
                new PayPalWrapper(EditRecordBase.pageBusyIndicator, EditRecordBase.myRecaptchaHandler); // Instantiate PayPal payment handler
            }
            else
                new PayPalWrapper(EditRecordBase.pageBusyIndicator, null); // Instantiate PayPal payment handler without recaptcha
        }).finally(() => { this.pPageBusyIndicator?.Decrement(); })


        this.DisplayCartDataFromCookie();
        this.WireUpDeleteLinks();
    }

    private DisplayCartDataFromCookie(): void {
        const CartTable = document.getElementById('tblCart') as HTMLTableElement;
        const tbody = CartTable.getElementsByTagName('tbody')[0];

        tbody.innerHTML = ''; // Remmove prior contents (in case the user deleted a single row)

        let GrandTotal = 0;
        const cartitems = CartCookie.GetBTCCartCookie();
        if (cartitems !== null) {
            cartitems!.forEach(item => {
                let tr = document.createElement("tr") as HTMLTableRowElement;

                const tdProdCodeDesc = document.createElement("td");
                let sMsg = item.Message??"";
                if (sMsg.length > 25)
                    sMsg = sMsg.substring(0, 25) + '...';
                tdProdCodeDesc.innerText = item.ProdName + (sMsg.length > 0 ? ' - ' + sMsg : ""); // Append shortened version of user's message
                tr.append(tdProdCodeDesc);

                const tdUnitPrice = document.createElement("td");
                tdUnitPrice.innerText = "$" + item.UnitPrice.toFixed(2);
                tr.append(tdUnitPrice);

                const tdQty = document.createElement("td");
                tdQty.innerText = item.Qty.toString();
                tr.append(tdQty);

                const LinePrice = item.Qty * item.UnitPrice;

                const tdLineTotal = document.createElement("td");
                tdLineTotal.innerText = "$" + LinePrice.toFixed(2);
                tr.append(tdLineTotal);

                const tdDeleteLine = document.createElement("td");
                tdDeleteLine.innerHTML = `<a class="DeleteCartLine" Data-guid="${item.GuidKey}">x</a>`;
                tr.append(tdDeleteLine);

                GrandTotal += LinePrice;

                tbody.append(tr);
            });
        }
        // Add grand total line to table
        let tr = document.createElement("tr") as HTMLTableRowElement;

        const tdProdCodeDesc = document.createElement("td");
        tdProdCodeDesc.innerHTML = "<strong>Total</strong>";
        tdProdCodeDesc.colSpan = 3;
        tr.append(tdProdCodeDesc);

        const tdLineTotal = document.createElement("td");
        tdLineTotal.innerHTML = "<strong>$" + GrandTotal.toFixed(2) + "</strong>";
        tr.append(tdLineTotal);

        const tdDeleteLine = document.createElement("td");
        tdDeleteLine.innerHTML = "&nbsp;";
        tr.append(tdDeleteLine);

        tbody.append(tr);

        if (GrandTotal !== 0) {
            // Show billing section and paypal buttons
            (document.getElementById(this.FormId) as HTMLFormElement).style.display = "block";
            (document.getElementById("divPayPalButtons") as HTMLFormElement).style.display = "block";
            (document.getElementById("divEmptyCartMsg") as HTMLDivElement).style.display = "None";

            (document.getElementById("idHiddenOrderTotal") as HTMLInputElement).value = GrandTotal.toFixed(2);
        }
        else {
            (document.getElementById(this.FormId) as HTMLFormElement).style.display = "none";
            (document.getElementById("divPayPalButtons") as HTMLFormElement).style.display = "none";
            (document.getElementById("divEmptyCartMsg") as HTMLDivElement).style.display = "block";
        }
    }

    private WireUpDeleteLinks(): void {
        // foreach row, add a link to let user delete that item from cart
        const cartDeleteAnchors = document.getElementsByClassName("DeleteCartLine") as HTMLCollectionOf<HTMLAnchorElement>;
        for (const aDelete of cartDeleteAnchors) {
            aDelete.onclick = async () => { // When user clicks to delete a product from the cart
                CartCookie.DeleteCartItem(aDelete.dataset.guid!);
                this.DisplayCartDataFromCookie();
                this.WireUpDeleteLinks(); // Not recursive
            }
        }
    }
}
