import jQuery from "jquery";
import { PageBusyIndicator } from "./PageBusyIndicator";
import { ReportErrorToServer } from "./ReportErrorToServer";

export abstract class SendEmailBase {
    protected pageBusyIndicator = new PageBusyIndicator();
    protected promFromSelectLoaded: Promise<void>;

    constructor() {
        this.promFromSelectLoaded = this.LoadCandidateSenderRolessFromServer();
        this.GetMyTestingEmailAddressFromServer();
        this.GetMaxFileSizeFromServer();

        (document.getElementById('Submit') as HTMLButtonElement).addEventListener('click', () => { this.DoSubmit(); });

        const elFiles = document.getElementById('FileUpload') as HTMLInputElement | null;
        elFiles?.addEventListener('change', () => this.ValidateFileSizes(elFiles));
        this.pageBusyIndicator.Decrement();
    }

    protected abstract CalcPostToURL(): string;

    private GetMyTestingEmailAddressFromServer(): void {
        const getURL: string = '/BTCAPI/Members/GetMyAcct?From=SendEmailBase';
        jQuery.get(getURL, (JsonData: { email: string }) => {
            (document.getElementById('MyEmailAddress') as HTMLSpanElement).textContent = JsonData.email;
        })
        .fail(async (jqXHR, textStatus, errorThrown) => {
            await ReportErrorToServer.DisplayAndProcessAjaxFailure(getURL, "GET", jqXHR, textStatus, errorThrown, null, null)
        });
    }

    private GetMaxFileSizeFromServer(): void {
        const elemMaxFileSizeSpan = document.getElementById('MaxFilesSizeMB') as HTMLSpanElement;
        if (elemMaxFileSizeSpan !== null) { // Have support for attachments?
            const getURL: string = '/BTCAPI/GeneralAndGroupEmails/GetMaxAttachmentSize';
            jQuery.get(getURL, (MaxFilesSizeBytesMB: number) => {
                (document.getElementById('MaxFilesSizeBytes') as HTMLInputElement).value = (MaxFilesSizeBytesMB * 1024 * 1024).toString();
                elemMaxFileSizeSpan.textContent = MaxFilesSizeBytesMB.toString();
            })
            .fail(async (jqXHR, textStatus, errorThrown) => {
                if (errorThrown !== "Unauthorized") // Ignore unuthorized error since that's expected for not-logged in users
                    await ReportErrorToServer.DisplayAndProcessAjaxFailure(getURL, "GET", jqXHR, textStatus, errorThrown, null, null)
            });
        }
    }

    private async LoadCandidateSenderRolessFromServer(): Promise<void> {
        const getURL: string = '/BTCAPI/GeneralAndGroupEmails/GetCandidateSenderRoles/';
        const select = document.getElementById("FromSenderRoleId") as HTMLSelectElement;
        await jQuery.get(getURL, (JsonData: [{ roleId: number, username: string }]) => {
            JsonData.forEach((member, i) => {
                const o = document.createElement('option') as HTMLOptionElement;
                o.value = member.roleId.toString();
                o.textContent = member.username;
                if (i === 0)
                    o.selected = true; // Select first entry
                select.append(o);
            });
        })
        .fail(async (jqXHR, textStatus, errorThrown) => {
            await ReportErrorToServer.DisplayAndProcessAjaxFailure(getURL, "GET", jqXHR, textStatus, errorThrown, null, null)
        });
    }

    private ValidateFileSizes(elFiles: HTMLInputElement): void { // From: https://stackoverflow.com/a/8758614
        let TotalFilesSize = 0;
        if (elFiles.files !== null)
            Array.from(elFiles.files).forEach((file) => TotalFilesSize += file.size);

        let Valid = true;
        document.getElementById('ErrorMessage')!.innerHTML = '';

        const MaxAllowedAttachmentsSizeBytes = parseInt((document.getElementById('MaxFilesSizeBytes') as HTMLInputElement).value);
        if (TotalFilesSize > MaxAllowedAttachmentsSizeBytes) {
            document.getElementById('ErrorMessage')!.innerHTML = "Error: The size of the attachments is " +
                Math.round(TotalFilesSize / 1024 / 1024) +
                "MB. The limit is " + Math.round(MaxAllowedAttachmentsSizeBytes / 1024 / 1024) + "MB";
            Valid = false;
        }

        (document.getElementById("Submit") as HTMLButtonElement).disabled = !Valid;
    }

    protected DoSubmit(): void {
        this.pageBusyIndicator.Increment();

        jQuery('#UploadProgress').show();
        jQuery('#SuccessMessage').hide();
        jQuery('#ErrorMessage').text('');

        const MyForm = new FormData(document.getElementById("RunEmailSetup") as HTMLFormElement);
        jQuery('form').prop("disabled", true).fadeTo(0, 0.5); // Disable while processing Ajax

        //const URL: string = this.PostAPIUrl;
        const URL: string = this.CalcPostToURL();
        const HttpVerb = "POST";
        // Note: Use "await" only with jQuery.get or jQuery.ajax with verb GET because GET probably won't fail
        // But POST, PUT, and DELETE verbs might fail server-side validation and "await" will
        // cause "Uncaught (in promise)" to be written to console by caller even if it's handled by .fail()
        jQuery.ajax({
            method: HttpVerb,
            url: URL,
            data: MyForm,
            contentType: false, // Required for attachments upload: https://stackoverflow.com/a/60474506
            processData: false, // Block jQuery from attempting to parse the FormData assigned to the "data" parameter
            xhr: () => { // Async file uploads: https://stackoverflow.com/a/49730801 and https://stackoverflow.com/a/8758614
                const xhr = new window.XMLHttpRequest();
                xhr.upload.addEventListener("progress", (evt: ProgressEvent) => {
                    if (evt.lengthComputable && evt.total !== 0)
                        jQuery('progress').attr({ value: evt.loaded, max: evt.total });
                }, false);
                return xhr;
            },
            dataType: "json"
        })
            .done((JsonData: string) => {
                jQuery('#SuccessMessage').html(JsonData);
                jQuery('#SuccessMessage').show();
            })
            .fail(async (jqXHR, textStatus, errorThrown) => {
                await ReportErrorToServer.DisplayAndProcessAjaxFailure(URL, HttpVerb, jqXHR, textStatus, errorThrown, MyForm, null);
            })
            .always(async (a, b, c) => {
                jQuery('form').prop("disabled", false).fadeTo(0, 1); // Re-enable after processing Ajax
                jQuery('#UploadProgress').hide();
                await this.pageBusyIndicator.Decrement();
            });
    }
}
