import jQuery from "jquery";
import { PageBusyIndicator } from "../Lib/PageBusyIndicator";

interface RaceResult {
    fullEventName: string,
    meters: number,
    displayUnits: string,
    description: string,
    personName: string,
    time: string,
    pr: boolean,
    date: string
}

export class HomePageRaceResults {
    constructor(protected pageBusyIndicator: PageBusyIndicator) {
        this.GetRaceResults().then(async (raceResults) => {
            await this.DrawRaceResults(raceResults);
            this.pageBusyIndicator.Decrement()
        });
    }

    private async GetRaceResults(): Promise<RaceResult[]> {
        return await jQuery.get('/BTCAPI/RaceResultsReport/HomePageRaceResults', (json: RaceResult[]) => json);
    }

    private async DrawRaceResults(raceResults: RaceResult[]): Promise<void> {
        const divRaceResults = document.getElementById("FrontPageRaceResults") as HTMLDivElement;

        // Check results to see if there are any PRs
        let HavePR: boolean = false;
        for (let rr of raceResults) {
            if (rr.pr) {
                HavePR = true;
                break;
            }
        }

        let NumRacesProcessed: number = 0;
        let LastEventName: string = '';
        let Table: HTMLTableElement | null = null;
        let Body: HTMLTableSectionElement | null = null;
        for (let rr of raceResults) {
            if (rr.fullEventName !== LastEventName) { // Need to start new table?
                if (NumRacesProcessed >= 4) // Too many events?
                    break;

                LastEventName = rr.fullEventName;
                if (Table !== null) { // Have existing table? Then append to div
                    let divTableTitle = document.createElement("div") as HTMLDivElement;
                    divTableTitle.classList.add("TableLabel");
                    divTableTitle.innerText = LastEventName;
                    divRaceResults.append(divTableTitle);

                    divRaceResults.append(Table);
                }

                Table = document.createElement("table") as HTMLTableElement;
                Body = document.createElement("tbody");
                Table.append(Body);
                NumRacesProcessed++;
            }

            const tr = document.createElement("tr") as HTMLTableRowElement;

            const tdPerson = document.createElement("td");
            tdPerson.classList.add("PersonName");
            tdPerson.innerText = rr.personName;
            tr.append(tdPerson);

            const tdTime = document.createElement("td");
            tdTime.classList.add("TextAlign_Right");
            tdTime.innerText = rr.time;
            tr.append(tdTime);

            if (HavePR) {
                const tdPR = document.createElement("td");
                if (rr.pr) {
                    tdPR.classList.add("PRCheckText");
                    tdPR.classList.add("PRShownText");
                    tdPR.innerText = "PR";
                }
                else
                    tdPR.innerHTML = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"; // No PR for this row
                tr.append(tdPR);
            }


            Body!.append(tr);
        }

        const divTableTitle = document.createElement("div") as HTMLDivElement;
        divTableTitle.classList.add("TableLabel");
        divTableTitle.innerText = LastEventName;
        divRaceResults.append(divTableTitle);

        if (Table !== null)
            divRaceResults.append(Table);

    }
}
