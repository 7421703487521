import { EditRecordBase } from "../Lib/EditRecordBase";
import { ReportErrorToServer } from "../Lib/ReportErrorToServer";

export class EditSundayRun extends EditRecordBase {
    constructor(protected readonly FormId: string) {
        super(FormId, '/BTCAPI/SundayRuns');
        this.ApplyRecordIdToAnchor();
        this.GetDataFromServer();
    }

    private async GetDataFromServer(): Promise<void> {
        if (this.RecordId > 0) {
            const getURL: string = '/BTCAPI/SundayRuns/GetSundayRun/' + this.RecordId;
            await jQuery.get(getURL, (JsonData: {
                runTitle: string, miles: number, locationName: string,
                locationMapURL: string, courseDescription: string, courseMapURL: string,
                drinkSupportMethod: string, drinkNotes: string, miscNotes: string
            }) => {
                (document.getElementById('RunTitle') as HTMLInputElement).value = JsonData.runTitle;
                (document.getElementById('Miles') as HTMLInputElement).value = JsonData.miles.toString();
                (document.getElementById('LocationName') as HTMLInputElement).value = JsonData.locationName;
                (document.getElementById('LocationMapURL') as HTMLInputElement).value = JsonData.locationMapURL;
                (document.getElementById('CourseDescription') as HTMLTextAreaElement).textContent = JsonData.courseDescription;
                (document.getElementById('CourseMapURL') as HTMLTextAreaElement).value = JsonData.courseMapURL;

                (document.getElementById("Drinks" + JsonData.drinkSupportMethod) as HTMLInputElement).checked = true;

                (document.getElementById('DrinkNotes') as HTMLTextAreaElement).textContent = JsonData.drinkNotes;
                (document.getElementById('MiscNotes') as HTMLTextAreaElement).textContent = JsonData.miscNotes;

            })
                .fail(async (jqXHR, textStatus, errorThrown) => {
                    await ReportErrorToServer.DisplayAndProcessAjaxFailure(getURL, "GET", jqXHR, textStatus, errorThrown, null, null)
                });
        }
    }

    protected override async DoSubmit() {
        await super.DoSubmit();
        this.ApplyRecordIdToAnchor(); // If this Sunday Run is new, then there'll be a new RecordId
    }

    private ApplyRecordIdToAnchor(): void {
        if (this.RecordId > 0) // Have existing record?
            (document.getElementById('aSendSundayRunEmailPreview') as HTMLAnchorElement).href = "/admin/SendSundayRunEmail?RecordId=" + this.RecordId;
    }

    protected EnableDisableControls() {
        super.EnableDisableControls();

        if (this.RecordId > 0) { // Show these fields only if editing an existing record
            const aSendSundayRunEmailPreview: HTMLAnchorElement = document.getElementById("aSendSundayRunEmailPreview") as HTMLAnchorElement;
            const spanSaveToPreviewMessage: HTMLSpanElement = document.getElementById("spanSaveToPreviewMessage") as HTMLSpanElement;
            if (aSendSundayRunEmailPreview !== null) {
                if (this.IsDirty) {
                    aSendSundayRunEmailPreview.style.display = "none";
                    spanSaveToPreviewMessage.style.display = "inline";
                }
                else {
                    aSendSundayRunEmailPreview.style.display = "inline";
                    spanSaveToPreviewMessage.style.display = "none";
                }
            }
        }
    }
}
