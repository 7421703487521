// Load PayPal as module (not script tag)
import { loadScript, OnApproveActions, OnApproveData, PayPalScriptOptions, OnCancelledActions } from "@paypal/paypal-js";
import { ReportErrorToServer } from "./ReportErrorToServer";
import { PageBusyIndicator } from "./PageBusyIndicator";
import { MyRecaptchaHandler } from "./ReCaptchaHandler";

// Note: If uBlock Origin in turned on, console will show error: "Cross-Origin Request Blocked: The Same Origin Policy disallows reading the remote resource at https://www.sandbox.paypal.com/xoplatform/logger/api/logger?disableSetCookie=true. (Reason: CORS request did not succeed)"

export class PayPalWrapper {
    private static pageBusyIndicator: PageBusyIndicator;
    private static myRecaptchaHandler: MyRecaptchaHandler | null = null;

    constructor(protected ppageBusyIndicator: PageBusyIndicator, protected pmyRecaptchaHandler: MyRecaptchaHandler | null) {
        PayPalWrapper.pageBusyIndicator = ppageBusyIndicator;
        PayPalWrapper.myRecaptchaHandler = pmyRecaptchaHandler;
        this.DoLoadPayPal();
    }

    private static DevMode = () => window.location.hostname.indexOf("localhost") >= 0;
    private static fundingSource: any;

    private static GetPayPalClientId = () => PayPalWrapper.DevMode() ?
        "AcLY9eZfcmNhJp8O5LwHFKA6NdrNgVSDf-VGVAUD5JaSdyjzVnHOoUrG3WEUx6mBonCQqOdpsZzfvnUV" // Dev PayPal Client Id
        : "AR0OU48WX7bcbWXk9aPuIIUHeA5oNaR2E33uahRli7LrUNxlIw-FW7Z4Jr-plMutOlXnxL4IXX50z4gX"; // Prod PayPal Client Id

    private async DoLoadPayPal(): Promise<void> {
        PayPalWrapper.pageBusyIndicator.Increment();
        let ppOptions: PayPalScriptOptions = {
            clientId: PayPalWrapper.GetPayPalClientId(),
            components: ["buttons", "applepay"], // ApplePay only shows on iPhones?
            enableFunding: ["venmo"], // Required to allow Venmo
            disableFunding: ["card"], // Drop down for credit card info processes too slow with no progress indicator
            dataPageType: "checkout",
            //debug: (MyPayPal.DevMode() ? "true" : "false"),
            //buyerCountry: "US" // Needed for Venmo testing?
        };

        let paypal = await loadScript(ppOptions)
        if (paypal !== null && paypal.Buttons !== undefined) {
            try {
                let ppButtons = paypal.Buttons({
                    onClick: (data) => {
                        PayPalWrapper.fundingSource = data.fundingSource; // Remember which PayPal payment button user clicked
                    },
                    // style: { // Button styles commented to accept PayPal's default
                    //     shape: "rect",
                    //     layout: "vertical",
                    //     color: "gold",
                    //     label: "paypal",
                    // },
                    createOrder: this.MyCreateOrder,
                    onApprove: this.MyOnApprove,
                    // onError: this.MyOnError,
                    // onCancel: this.MyOnCancel

                });
                await ppButtons.render("#paypal-button-container");
            }
            catch (error) {
                const ErrMsg = "Failed to load the PayPal JS SDK script";
                console.error(ErrMsg, error);
                await ReportErrorToServer.ReportClientErrorToServer(ErrMsg, "DoLoadPayPal()", error);
            };
        }
        else {
            const ErrMsg = "PayPal object is null";
            console.error(ErrMsg);
            await ReportErrorToServer.ReportClientErrorToServer(ErrMsg, "DoLoadPayPal()");
        }
        PayPalWrapper.pageBusyIndicator.Decrement();
    }

    private static async SetupRecaptchaV3IfNeeded(): Promise<void> {
        if (PayPalWrapper.myRecaptchaHandler !== null) // Will be null and not needed if user is signed-in
            await PayPalWrapper.myRecaptchaHandler.GetNewOneTimeV3TokenIfNeeded();
    }

    private async MyCreateOrder(): Promise<string> { // In BTC checkout page, user has clicked PayPal payment button
        PayPalWrapper.pageBusyIndicator.Increment();
        await PayPalWrapper.SetupRecaptchaV3IfNeeded(); // V3 token is short-lived so must be requested at the moment the form is submitted.
        const URL = "/BTCAPI/PayPalCheckout/api/orders";
        let OrderId: string = "";

        await jQuery.post(
            URL,
            jQuery("#formBillingInfo").serialize(),
            async (orderData, textStatus, jqXHR) => { // Server returned success code
                if (orderData.id)  // Successful if have order id
                    OrderId = orderData.id;
                else {
                    const errorDetail = orderData?.details?.[0];
                    let ErrMsg = "";
                    if (errorDetail)
                        ErrMsg = `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`;
                    else {
                        ErrMsg = "<br>";
                        for (let errObj in orderData.errors)
                            ErrMsg += (ErrMsg !== "" ? "<br>" : "") + orderData.errors[errObj];
                    }

                    (document.getElementById("ErrorMessage") as HTMLDivElement).innerHTML = ErrMsg;
                    await ReportErrorToServer.ReportClientErrorToServer(ErrMsg, "MyCreateOrder()");
                }
            }
        ).catch(async (jqXHR, textStatus, errorThrown) => { // Unlike .fail(), .catch() will stop the exception from bubbling up
            await ReportErrorToServer.DisplayAndProcessAjaxFailure(URL, "POST", jqXHR, textStatus, errorThrown, null, null);
        }).always(() => {
            PayPalWrapper.pageBusyIndicator.Decrement();
        });

        return OrderId;
    }

    private async MyOnApprove(data: OnApproveData, actions: OnApproveActions): Promise<void> { // On PayPal, user has approved the payment
        PayPalWrapper.pageBusyIndicator.Increment();
        const URL = `/BTCAPI/PayPalCheckout/api/orders/${data.orderID}/capture`;
        await jQuery.post(
            URL,
            jQuery("#formBillingInfo").serialize(),
            async (orderData, textStatus, jqXHR) => {
                // Three cases to handle:
                //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                //   (2) Other non-recoverable errors -> Show a failure message
                //   (3) Successful transaction -> Show confirmation or thank you message

                const errorDetail = orderData?.details?.[0];

                if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                    // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                    // recoverable state, per
                    return actions.restart();
                } else if (errorDetail) { // (2) Other non-recoverable errors -> Show a failure message
                    const ErrMsg = `${errorDetail.description} (${orderData.debug_id})`;
                    (document.getElementById("ErrorMessage") as HTMLDivElement).innerHTML = ErrMsg;
                    await ReportErrorToServer.ReportClientErrorToServer(ErrMsg, "MyOnApprove()");
                    throw new Error(ErrMsg);
                } else if (!orderData.purchaseUnits) {
                    let ErrMsg = "purchanceUnits is missing. " + JSON.stringify(orderData);
                    (document.getElementById("ErrorMessage") as HTMLDivElement).innerHTML = ErrMsg;
                    await ReportErrorToServer.ReportClientErrorToServer(ErrMsg, "MyOnApprove()");
                    throw new Error(ErrMsg);
                } else {
                    // (3) Successful transaction
                    // const transaction =
                    //     orderData?.purchaseUnits?.[0]?.payments?.captures?.[0] ||
                    //     orderData?.purchaseUnits?.[0]?.payments?.authorizations?.[0];

                    // Uncomment this for debugging
                    //console.log("Capture result", orderData, JSON.stringify(orderData, null, 2));

                    let FundingSourceDesc: string;
                    switch (PayPalWrapper.fundingSource) {
                        case "paypal": FundingSourceDesc = "PayPal"; break;
                        case "card": FundingSourceDesc = "PayPal using credit/debut card"; break;
                        case "venmo": FundingSourceDesc = "Venmo"; break;
                        default: FundingSourceDesc = PayPalWrapper.fundingSource; break;
                    }

                    const PayerEmail = orderData?.payer?.emailAddress;
                    (document.getElementById("SuccessMessage") as HTMLDivElement).innerHTML =
                        `Payment of $${(document.getElementById("idHiddenOrderTotal") as HTMLInputElement).value} completed via ${FundingSourceDesc}. Thanks!`
                    + "<br><br>We've sent an email to " + PayerEmail
                        + " with details about your order.";
                    (document.getElementById("SuccessMessage") as HTMLDivElement).style.display = "block";
                    (document.getElementById("ErrorMessage") as HTMLDivElement).innerText = "";

                    (document.getElementById("tblCart") as HTMLTableElement).style.display = "none"; // Hide cart section
                    (document.getElementById("formBillingInfo") as HTMLFormElement).style.display = "none"; // Hide billing info section
                    (document.getElementById("divPayPalButtons") as HTMLDivElement).style.display = "none"; // Hide PayPal buttons section
                }

            }
        ).catch(async (jqXHR, textStatus, errorThrown) => { // Unlike .fail(), .catch() will stop the exception from bubbling up
            await ReportErrorToServer.DisplayAndProcessAjaxFailure(URL, "POST", jqXHR, textStatus, errorThrown, null, null);
        }).always(() => {
            PayPalWrapper.pageBusyIndicator.Decrement();
        });
    }

    // private MyOnError(err: Record<string, unknown>): void {
    //     const ErrMsg = err.toString();
    //     (document.getElementById("ErrorMessage") as HTMLDivElement).innerHTML = ErrMsg;
    //     console.error(ErrMsg);
    //     ReportErrorToServer.ReportClientErrorToServer(ErrMsg, "DoLoadPayPal()");
    // }

    // private MyOnCancel(err: Record<string, unknown>, actions: OnCancelledActions): void {
    //     const ErrMsg = err.toString();
    //     (document.getElementById("ErrorMessage") as HTMLDivElement).innerHTML = ErrMsg;
    //     console.error(ErrMsg);
    //     ReportErrorToServer.ReportClientErrorToServer(ErrMsg, "DoLoadPayPal()");
    // }
}
