import jQuery from "jquery";
import { ReportsBaseClass } from "../Lib/ReportsBaseClass"
import { ReportErrorToServer } from "../Lib/ReportErrorToServer";

export class SundayRuns extends ReportsBaseClass {
    constructor() {
        super('tableSundayRuns', '/BTCAPI/SundayRuns/GetSundayRuns');

        // When user edits Run Start Time field, store new time to DB
        document.getElementById('RunStartTime')!.addEventListener('change', this.SendRunStartTimeToServer);
        this.GetRunStartTimeFromServer();
        this.GetServerData();
    }

    private SendRunStartTimeToServer(evt: Event) : void {
        const PostToAPIUrl = '/BTCAPI/SundayRuns/SetRunStartTime';
        const tb: HTMLInputElement = evt.currentTarget as HTMLInputElement;
        const oData = { 'StartTime': tb.value };
        jQuery.post(PostToAPIUrl, oData)
            .done(() => jQuery('#ErrorMessage').html("")) // Clear prior err msg, if any
            .fail(async (jqXHR, textStatus, errorThrown) => {
                await ReportErrorToServer.DisplayAndProcessAjaxFailure(PostToAPIUrl, "POST", jqXHR, textStatus,
                    errorThrown, null, oData)
            });
    }

    private GetRunStartTimeFromServer(): void {
        const getURL: string = '/BTCAPI/SundayRuns/GetRunStartTime';
        jQuery.get(getURL, (startTime: string) => (document.getElementById('RunStartTime') as HTMLInputElement).value = startTime)
            .fail(async (jqXHR, textStatus, errorThrown) =>
                await ReportErrorToServer.DisplayAndProcessAjaxFailure(getURL, "GET", jqXHR, textStatus, errorThrown, null, null)
            );
    }

    ConvertRecordToTR(row: JQuery<HTMLTableRowElement>, record: any) : void {
        row.find('.spanTitle').html('<a href="/admin/EditSundayRun?RecordId=' + record.sundayRunId + '">' + record.runTitle + '</a>');
        row.find('.spanMiles').html(record.miles.toString());
        row.find('.spanLocationName').html(record.locationName);
        row.find('.spanCourseMap').html(record.courseMapURL);
    }

    QueryString() : string {
        let qFilters: string = '';

        const TitlesFilter: string = (document.getElementById('TitlesFilter') as HTMLInputElement).value;
        if (TitlesFilter !== '')
            qFilters += '&TitlesFilter=' + encodeURI(TitlesFilter);

        return qFilters;
    }
}
