import jQuery from "jquery";
import { UserEditableHtmlContent } from "../Lib/UserEditableHtmlContent";
import { ReportErrorToServer } from "../Lib/ReportErrorToServer";
import { SendEmailBase } from "../Lib/SendEmailBase";

export class SendSundayRunEmail extends SendEmailBase {
    private SundayRunId: number = -1;

    constructor() {
        super();
        new UserEditableHtmlContent(document.getElementById("divSetupOneTimeMessage") as HTMLDivElement);

        this.pageBusyIndicator.Increment();

        jQuery.each(jQuery('input[name="SendEmailMode"]'), (_, rb) => rb.addEventListener('change', () => {
            if ((document.getElementById('TestMode') as HTMLInputElement).checked)
                (document.getElementById('Submit') as HTMLButtonElement).value = 'Send One Test Email';
            else
                (document.getElementById('Submit') as HTMLButtonElement).value = 'Send to Everyone';
        }));

        jQuery('#Submit').prop("disabled", false).fadeTo(0, 1); // Re-enable submit button after processing Ajax

        document.getElementById("IsPrivate")?.addEventListener("change", () => this.WriteEmailPublicPrivateRunMessage());
        document.getElementById('FromSenderRoleId')!.addEventListener('change', () => this.BuildEmailBodyPreview());

        // Update email preview when user changes special message content
        document.getElementById('divSetupOneTimeMessage')?.addEventListener('input', () => this.WriteEmailSpecialNoteIntoEmail());
        document.getElementById('divSetupOneTimeMessage')?.addEventListener('paste', () => this.WriteEmailSpecialNoteIntoEmail());

        this.SundayRunId = this.GetRecordIdFromQueryString();
        this.promFromSelectLoaded.then(async () => await this.BuildEmailBodyPreview());
        this.pageBusyIndicator.Decrement();
    }

    private GetRecordIdFromQueryString(): number {
        const qs = window.location.search.substring(1); // Remove leading "?", if any
        const searchParams = new URLSearchParams(qs);
        if (searchParams.has("RecordId"))  // existing event?
            return parseInt(searchParams.get("RecordId")!);
        return -1; // -1 means no record
    }

    protected override CalcPostToURL(): string {
        return '/BTCAPI/SundayRunEmail/SendEmail/' + this.SundayRunId;
    }

    private async BuildEmailBodyPreview(): Promise<void> {
        this.pageBusyIndicator.Increment();
        this.CopySpecialMsgFromDivToHiddenTextArea();
        const URL: string = '/BTCAPI/SundayRunEmail/GetEmailBodyPreview/' + this.SundayRunId +
            "?FromSenderRoleId=" + (document.getElementById('FromSenderRoleId')! as HTMLSelectElement).value +
            "&taSpecialOneTimeMessage=" + (document.getElementById('taSpecialOneTimeMessage')! as HTMLTextAreaElement).value +
            "&PrivateWorkout=" + ((document.getElementById('IsPrivate') as HTMLInputElement).checked ? "true" : "false");

        await jQuery.get(
            URL,
            (HTMLFromServer: string) => document.getElementById('divEmailBodyPreview')!.innerHTML = HTMLFromServer
        )
        .fail(async (jqXHR, textStatus, errorThrown) =>
            await ReportErrorToServer.DisplayAndProcessAjaxFailure(URL, "GET", jqXHR, textStatus, errorThrown, null, null))
        .always((_, __, ___) => {
            this.pageBusyIndicator.Decrement();
        });
    }

    protected DoSubmit(): void {
        this.CopySpecialMsgFromDivToHiddenTextArea();
        super.DoSubmit();
    }

    private CopySpecialMsgFromDivToHiddenTextArea(): void {
        // To support text formatting, special message is typed into an editable div element. But div content is
        // not posted to the server as part of the form data. So the div's innerHTML needs to be
        // copied into the hidden textarea element in form.
        const taSpecialOneTimeMessage: HTMLTextAreaElement = document.getElementById("taSpecialOneTimeMessage") as HTMLTextAreaElement;
        const divSetupOneTimeMessage: HTMLDivElement = document.getElementById("divSetupOneTimeMessage") as HTMLDivElement;
        if (taSpecialOneTimeMessage !== null && divSetupOneTimeMessage !== null)
            taSpecialOneTimeMessage.value = divSetupOneTimeMessage.innerHTML;
    }

    private WriteEmailPublicPrivateRunMessage() {
        let Msg = "The BTC Sunday Long Run is open to everyone, members and non-members.";
        if ((document.getElementById('IsPrivate') as HTMLInputElement).checked) // Is private this week?
            Msg = "<strong>Note:</strong> This week's BTC Sunday Long Run is private, for BTC members only.";

        const elMsg: HTMLSpanElement = (document.getElementById('PublicPrivateMsg') as HTMLSpanElement);
        if (elMsg !== null) // Could be null if, for eg, an unknown SundayRunId was sent to server.
            elMsg.innerHTML = Msg;
    }

    private WriteEmailSpecialNoteIntoEmail() {
        const divSpecialNoteHtml = document.getElementById('divSetupOneTimeMessage') as HTMLDivElement;
        // Check for standalone <br> since some browsers will embed it (eg Firefox, Chrome)
        if (divSpecialNoteHtml.innerHTML === "" || divSpecialNoteHtml.innerHTML === "<br>")
            jQuery("#pEmailSpecialMessage").hide();
        else {
            jQuery("#pEmailSpecialMessage").show();
            (document.getElementById('spanEmailSpecialMessage') as HTMLSpanElement).innerHTML = divSpecialNoteHtml.innerHTML;
        }
    }
}
