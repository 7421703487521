import jQuery from "jquery";
import 'jquery-ui/ui/widgets/tabs';
import 'jquery-ui/themes/base/theme.css' // From: https://stackoverflow.com/a/45159042
import 'jquery-ui/themes/base/tabs.css'
import { ReportsBaseClass } from "../Lib/ReportsBaseClass"

export class RaceResultsReport {
    private repGeneral: GeneralResults = new GeneralResults();
    private repMostProlific: MostProlific = new MostProlific();
    private repMostPopularEvents: MostPopularEvents = new MostPopularEvents();
    private repMostPopularLocations: MostPopularLocations = new MostPopularLocations();
    private repBestTimesWomen: BestTimes = new BestTimes('F');
    private repBestTimesMen: BestTimes = new BestTimes('M');

    constructor() {
        jQuery('input[type=radio][name=PaceUnits]').each((_, rb) => {
            rb.addEventListener('change', () =>
                localStorage.setItem('PaceUnits', ((document.querySelector('input[type=radio][name=PaceUnits]:checked') as HTMLInputElement).value)))
        });

        document.getElementById('ShowGeneralFilters')!.addEventListener('change', () => jQuery("#divRaceResultsGeneralTabFilters").toggle('fast'));
        // Make sure display of filters matches its controlling checkbox (since F5 will reset styles, but not input element values)
        if ((document.getElementById('ShowGeneralFilters') as HTMLInputElement).checked)
            jQuery("#divRaceResultsGeneralTabFilters").show();
        else
            jQuery("#divRaceResultsGeneralTabFilters").hide();

        try { // localStorage may not exist or may not be enabled.
            const PaceUnits: string = localStorage.getItem('PaceUnits')!; // ! means guaranteed not to be null.
            if (PaceUnits === 'Metric')
                (document.getElementById('PaceMinPerKm') as HTMLInputElement).checked = true;
        }
        catch (e) {
            //alert(e);
        }

        jQuery('#RaceResultsTabs').tabs({
            activate: () => this.RunTabReport()
        });

        document.getElementById('Years')!.addEventListener('change', () => this.RunTabReport());

        this.LoadYearsSelect();
        this.RunTabReport();
    }

    LoadYearsSelect() {
        const CurrentYear: number = (new Date()).getFullYear();
        const YearsSelect: HTMLSelectElement = document.getElementById('Years') as HTMLSelectElement;

        for (let year = CurrentYear; year >= 2012; year--) {
            YearsSelect.add(new Option(year.toString(), year.toString()));
        }

        YearsSelect.selectedIndex = 1; // Index 0 is "All Years"
    }

    RunTabReport() {
        const TargetYear: number = parseInt((document.getElementById('Years') as HTMLSelectElement).value);
        jQuery('Span.spanTargetYear').each((n: number, li: HTMLSpanElement) => {
            li.textContent = (TargetYear === -1 ? "All Years" : TargetYear.toString())
        });

        const activeTab: number = jQuery('#RaceResultsTabs').tabs("option", "active");
        switch (activeTab) {
            case 0: this.repGeneral.GetServerData(); break;
            case 1: this.repMostProlific.GetServerData(); break;
            case 2: this.repMostPopularEvents.GetServerData(); break;
            case 3: this.repMostPopularLocations.GetServerData(); break;
            case 4:
                this.repBestTimesWomen.GetServerData();
                this.repBestTimesMen.GetServerData();
                break;
        }
    }
}


class GeneralResults extends ReportsBaseClass {
    constructor() {
        super('tableGeneralResults', "/BTCAPI/RaceResultsReport/GeneralResults", true);

        document.getElementById('IncludeLocation')!.addEventListener('change', () => this.UpdateGeneralRepCosmetics());
        document.getElementById('IncludeComments')!.addEventListener('change', () => this.UpdateGeneralRepCosmetics());
        document.getElementById('PaceMinPerMile')!.addEventListener('change', () => this.UpdateGeneralRepCosmetics());
        document.getElementById('PaceMinPerKm')!.addEventListener('change', () => this.UpdateGeneralRepCosmetics());
    }

    private UpdateGeneralRepCosmetics() {
        const IncludeLocation: HTMLInputElement = document.getElementById('IncludeLocation') as HTMLInputElement;
        const colLocation = jQuery('.colLocation');
        if (IncludeLocation.checked)
            colLocation.show();
        else
            colLocation.hide();

        const IncludeComments: HTMLInputElement = document.getElementById('IncludeComments') as HTMLInputElement;
        const colComments = jQuery('.colComments');
        if (IncludeComments.checked)
            colComments.show();
        else
            colComments.hide();

        const colPaceImperial = jQuery('.colPaceImperial');
        const colPaceMetric = jQuery('.colPaceMetric');
        const PaceUnits: string = (document.querySelector('input[name=PaceUnits]:checked') as HTMLInputElement).value;
        if (PaceUnits === "Imperial") {
            colPaceImperial.show();
            colPaceMetric.hide();
        }
        else {
            colPaceImperial.hide();
            colPaceMetric.show();
        }

        if (IncludeComments.checked)
            colComments.show();
        else
            colComments.hide();
    }

    ChildClassPostProcess() {
        this.UpdateGeneralRepCosmetics();
    }

    ConvertRecordToTR(row: JQuery<HTMLTableRowElement>, record: any) {
        row.find('.Date').text(record.eventDate);
        row.find('.Event').text(record.eventName + (record.description === "" ? "" : " (" + record.description + ")"));
        row.find('.Distance').text(record.distanceConverted);
        row.find('.Participant').text(record.personName);

        row.find('.spanLocation').text(record.location);
        row.find('.Time').text(record.timeConverted);
        row.find('.spanPaceImperial').text(record.paceImperial);
        row.find('.spanPaceMetric').text(record.paceMetric);
        row.find('.Gender').text(record.gender);
        row.find('.GenderPlace').text(record.genderPlace);
        row.find('.DivPlace').text(record.divisionPlace);
        //row.find('.PR').html(record.PR === '0' ? '' : '✓'); // UnicodeCheckmark
        if (!record.pr) // Not a PR?
            row.find('.PRCheckMark').css("display", "none");
        row.find('.spanComments').text(record.comment);

        if (record.genderPlace !== null) {
            const GenderPlace: number = parseInt(record.genderPlace);
            if (GenderPlace === 1)
                row.find('.GenderPlace').css('background-color', 'Gold');
            else if (GenderPlace === 2)
                row.find('.GenderPlace').css('background-color', 'Silver');
            else if (GenderPlace === 3)
                row.find('.GenderPlace').css('background-color', '#cd7f32');
        }
    }

    QueryString() : string {
        let qFilters: string = 'targetYear=' + parseInt((document.getElementById('Years') as HTMLSelectElement).value);

        if ((document.getElementById('ShowGeneralFilters') as HTMLInputElement).checked) {
            const EventsFilter: string = (document.getElementById('EventsFilter') as HTMLInputElement).value;
            if (EventsFilter !== '')
                qFilters += '&EventName=' + encodeURI(EventsFilter);

            const ParticipantName: string = (document.getElementById('ParticipantsFilter') as HTMLInputElement).value;
            if (ParticipantName !== '')
                qFilters += '&ParticipantsFilter=' + encodeURI(ParticipantName);

            const PRFilter: HTMLInputElement = document.getElementById('PRFilter') as HTMLInputElement;
            qFilters += '&PRsOnly=' + (PRFilter.checked ? 'true' : 'false');

            const GenderFilter: string = (document.querySelector('input[name=Gender]:checked') as HTMLInputElement).value;
            if (GenderFilter !== 'Both')
                qFilters += "&Gender=" + GenderFilter;

            qFilters += "&PaceUnits=" + (document.querySelector('input[name=PaceUnits]:checked') as HTMLInputElement).value;
        }
        return qFilters;
    }
}

class MostProlific extends ReportsBaseClass {
    constructor() { super('tableTopProlific', "/BTCAPI/RaceResultsReport/MostProlific", false); }

    ConvertRecordToTR(row: JQuery<HTMLTableRowElement>, record: any) {
        row.find('.RacerName').text(record.racerName);
        row.find('.NumRaces').text(record.numRaces);
    }

    QueryString() : string {
        let qFilters: string = 'targetYear=' + parseInt((document.getElementById('Years') as HTMLSelectElement).value);
        return qFilters;
    }
}

class MostPopularEvents extends ReportsBaseClass {
    constructor() { super('tableTopEvents', "/BTCAPI/RaceResultsReport/MostPopularEvents", false); }

    ConvertRecordToTR(row: JQuery<HTMLTableRowElement>, record: any) {
        row.find('.Event').text(record.eventName);
        row.find('.Date').text(record.date);
        row.find('.NumRacers').text(record.numRacers);
    }

    QueryString(): string {
        let qFilters: string = 'targetYear=' + parseInt((document.getElementById('Years') as HTMLSelectElement).value);

        return qFilters;
    }
}

class MostPopularLocations extends ReportsBaseClass {
    constructor() { super('tableTopLocations', "/BTCAPI/RaceResultsReport/MostPopularLocations", false); }

    ConvertRecordToTR(row: JQuery<HTMLTableRowElement>, record: any) {
        row.find('.Country').text(record.country);
        row.find('.State').text(record.stateCode);
        row.find('.City').text(record.city);
        row.find('.NumResults').text(record.numResults);
    }

    QueryString(): string {
        let qFilters: string = 'targetYear=' + parseInt((document.getElementById('Years') as HTMLSelectElement).value);

        return qFilters;
    }
}

class BestTimes extends ReportsBaseClass {
    constructor(public gender: string) {
        super(gender === 'F' ? 'tableBestTimesWomen' : 'tableBestTimesMen', "/BTCAPI/RaceResultsReport/BestTimes", false);
    }

    ConvertRecordToTR(row: JQuery<HTMLTableRowElement>, record: any) {
        row.find('.EventDate').text(record.eventDate);
        row.find('.RacerName').text(record.racerName);
        row.find('.EventName').text(record.eventName);
        row.find('.EventType').text(record.eventType);
        row.find('.Description').text(record.description);
        row.find('.City').text(record.city);
        row.find('.StateCode').text(record.stateCode);
        row.find('.Country').text(record.country);
        row.find('.DistanceConverted').text(record.distanceConverted);
        row.find('.TimeConverted').text(record.timeConverted);
    }

    QueryString() {
        let qFilters: string = 'targetYear=' + parseInt((document.getElementById('Years') as HTMLSelectElement).value);
        qFilters += '&gender=' + this.gender;
        return qFilters;
    }
}
