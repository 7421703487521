import jQuery from "jquery";
import { PageBusyIndicator } from "./PageBusyIndicator";
import { ReportErrorToServer } from "./ReportErrorToServer";

export abstract class ReportsBaseClass {
    private pageBusyIndicator = new PageBusyIndicator();

    constructor(private tableId: string, private APIRoute: string, private ShowRecordCounts: boolean = true) {
        this.SetupFilterEvents();
        this.pageBusyIndicator.Decrement();
    }

    private bCallRunningViaAjax: boolean = false;
    private HaveUnappliedFilter: boolean = false;

    private SetupFilterEvents() {
        // Re-run report on filter changes
        document.querySelectorAll('.GeneralReportSelectFilter')?.
            forEach((elem) => elem.addEventListener("change", () => this.GetServerData()));

        document.querySelectorAll('.ReportTextFilter')?.
            forEach((elem) =>
                ['input', 'paste'].
                    forEach((action) => elem.addEventListener(action, () => this.GetServerData()))
            );

        document.querySelectorAll('.ReportRadioCheckboxFilter')?.
            forEach((elem) => elem.addEventListener("change", () => this.GetServerData()));
    }

    public GetServerData() {
        if (this.bCallRunningViaAjax) {
            this.HaveUnappliedFilter = true;
            return;
        }
        this.bCallRunningViaAjax = true;
        this.pageBusyIndicator.Increment();
        if (jQuery('#NumRecords').length)
            jQuery('#NumRecords').hide();
        jQuery('#' + this.tableId).hide(); // Hide prior results
        const qs = this.QueryString();
        const URL: string = this.APIRoute + (qs.length === 0 ? "" : "?" + qs);
        const HttpVerb = "GET";
        // ASP.Net: The Accept header is ignored. The content is returned in JSON, unless otherwise configured. (https://learn.microsoft.com/en-us/aspnet/core/web-api/advanced/formatting?view=aspnetcore-8.0#browsers-and-content-negotiation-2)
        jQuery.get(URL)
            .done((JsonData: []) => this.ServerCallDone(JsonData))
            .fail(async (jqXHR, textStatus, errorThrown) => {
                await ReportErrorToServer.DisplayAndProcessAjaxFailure(URL, HttpVerb, jqXHR, textStatus, errorThrown, null, null);
            })
            .always(() => {
                this.pageBusyIndicator.Decrement();
                this.bCallRunningViaAjax = false;
                if (this.HaveUnappliedFilter) {
                    this.HaveUnappliedFilter = false;
                    this.GetServerData();
                }
            })
            ;
    }

    // Convert Json results into a set of <tr> and insert into <table>.
    protected ServerCallDone(JsonData: any) { // Derived class may override for different report rendering
        if (this.HaveUnappliedFilter) // Don't bother rendering results yet since there are more filter changes being processed.
            return;
        jQuery('#' + this.tableId + ' tbody tr.MyDataRow').remove(); // Remove all prior TR's (except the template row).

        if (JsonData !== null && JsonData instanceof Array) {
            const newrows = new Array<JQuery<HTMLTableRowElement>>;

            for (const dbServerRow of JsonData) {
                const newTR =
                    jQuery('#' + this.tableId + ' .RowTemplate').clone().removeClass('RowTemplate')
                        .addClass('MyDataRow') as JQuery<HTMLTableRowElement>
                this.ConvertRecordToTR(newTR, dbServerRow); // Convert each Json record to <TR> element.
                newrows.push(newTR);
            }

            jQuery('#' + this.tableId + ' tbody').append(newrows);

            if (jQuery('#NumRecords').length && this.ShowRecordCounts)
                jQuery('#NumRecords').text(JsonData.length + ' result' + (JsonData.length === 1 ? '' : 's')).show();

            this.ChildClassPostProcess();
            jQuery('#' + this.tableId).show();
        }
        else {
            jQuery('#' + this.tableId).hide();
        }
    }

    protected ChildClassPostProcess(): void {}  // Override in subclass if needed.
    protected ConvertRecordToTR(row: JQuery<HTMLTableRowElement>, record: any): void { }
    protected QueryString() { return ''; } // Override in subclass if needed.
}
