
// There may be multiple reasons a page is busy. Use this class to track a
// count of them and display the busy indicator as long as something is still busy.
export class PageBusyIndicator {
    private count = 1; // Default to 1 to start page in busy state.

    public Increment = async () => {
        this.count++;
        await this.ShowHideBusyIndicator();
    }

    public Decrement = async () => {
        if (this.count >= 0) {
            this.count--;
            await this.ShowHideBusyIndicator();
        }
    }

    public IsBusy = () => this.count > 0;

    private ShowHideBusyIndicator = async () => {
        // Don't hard code element id and styles. Instead, pass them to ctor
        const spinner = document.getElementById("idReportSpinner");
        if (spinner !== null)
            spinner.style.display = (this.count > 0 ? 'inline-block' : 'none');
    }
}
