import jQuery from "jquery";
import { PageBusyIndicator } from "../Lib/PageBusyIndicator";

interface Event {
    title: string,
    eventDate: string,
    eventTime: string,
    description: string
}

export class HomePageEvents {
    constructor(protected pageBusyIndicator: PageBusyIndicator) {
        jQuery.get('/BTCAPI/Members/IsUserActive')
            .then(async (IsUserActive: boolean) => {
                if (IsUserActive) {
                    const events = await this.GetFutureEvents();
                    if (events !== null && events.length > 0) { // Have some events?
                        await this.DrawEvents(events);
                        (document.getElementById("divEvents") as HTMLDivElement).style.display = "block";
                    }
                }
            })
            .always(() => this.pageBusyIndicator.Decrement());
    }

    private async GetFutureEvents(): Promise<Event[]> {
        const URL = '/BTCAPI/Events/GetEvents?FutureEvents=Yes';
        return await jQuery.get(URL, (json: Event[]) => json);
    }

    private async DrawEvents(events: Event[]): Promise<void> {
        const tbody = document.getElementById("tblEvents")?.getElementsByTagName('tbody')[0];

        events.forEach(event => {
            const tr = document.createElement("tr") as HTMLTableRowElement;

            const tdTitle = document.createElement("td");
            tdTitle.innerText = event.title;
            tr.append(tdTitle);

            const tdWhen = document.createElement("td");
            tdWhen.innerText = event.eventDate + " " + event.eventTime;
            tr.append(tdWhen);


            const tdDesc= document.createElement("td");
            tdDesc.innerText = event.description;
            tr.append(tdDesc);

            tbody?.append(tr);
        })
    }
}
