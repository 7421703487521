declare global {
    interface Window {
        onV2VerifiedNotARobot: () => Promise<void>; // Create  function type on window object to assign recaptcha V2 handler
    }
}

export class MyRecaptchaHandler {
    private bRecaptchaV3Available = false;
    private defRecaptchaSetupDone = jQuery.Deferred(); // Use to indicate background setup processing is done
    private defUserAccepted = jQuery.Deferred(); // Use to enable submit button when recatpcha test is passed
    private UserPassedRecaptchaTest = false;

    constructor(protected readonly V3FormName: string, protected readonly V3SubmittedAction: string) {
        this.loadRecaptchaScript().then(() => {
            grecaptcha.ready(async () => {
                // Assign call-back function to run when reCaptcha V2 test has been passed by user
                window.onV2VerifiedNotARobot = async () => {
                    this.UserPassedRecaptchaTest = true;
                    await this.defUserAccepted.resolve();
                }

                // Check if reCaptcha V3 is available, otherwise setup V2
                const OneTimeV3Token = await grecaptcha.execute(MyRecaptchaHandler.GetSiteKeyV3(), { action: this.V3FormName });
                const URL = '/BTCAPI/Recaptcha/reCaptchaPassiveV3Check/' + this.V3FormName + '/' + OneTimeV3Token;
                const jsonV3AvailabilityResp = await fetch(URL);
                if (jsonV3AvailabilityResp.status === 503)  // Service unavailable?
                    jQuery('#ErrorMessage').html("BTC API is offline or inaccessible");
                this.bRecaptchaV3Available = jsonV3AvailabilityResp.status === 200 && await jsonV3AvailabilityResp.json() as boolean;
                const elem = document.getElementsByClassName('g-recaptcha')[0] as HTMLElement;
                if (this.bRecaptchaV3Available) {
                    grecaptcha.render(elem, { 'sitekey': MyRecaptchaHandler.GetSiteKeyV3() });
                    // V3 tokens expire in two minutes, so don't bother saving it (https://developers.google.com/recaptcha/docs/v3)
                    this.UserPassedRecaptchaTest = true;
                    this.StoreV3Token("V3IsAvailable_ReplaceThisWithRealV3Token"); // Store placeholder in page to indicate V3 is available
                    await this.defUserAccepted.resolve(); // Notify form user has been accepted as not a bot (by V3)
                }
                else { // No V3 available so setup recaptcha V2
                    grecaptcha.render(elem, { 'sitekey': MyRecaptchaHandler.GetSiteKeyV2() });
                    jQuery('.g-recaptcha').prop("style", "display:block"); // Make V2 recaptcha visible
                }
                await this.defRecaptchaSetupDone.resolve(); // Tell form long-ish recaptcha setup is done so spinner can be hidden
            });
        }).catch((reason) => {
            console.error(reason);
        })
    }

    // Dynamically load recaptcha script if needed
    private loadRecaptchaScript() {
        return new Promise((resolve, reject) => {
            const script = (document.createElement('script') as HTMLScriptElement);
            script.src = `https://www.google.com/recaptcha/api.js?render=${MyRecaptchaHandler.GetSiteKeyV3()}`; // Recaptcha SiteKey V3
            script.onload = resolve;
            script.onerror = reject;
            document.body.appendChild(script);
        });
    }

    private static GetSiteKeyV2(): string {
        if (window.location.hostname.indexOf("localhost") >= 0) // Running on Dev?
            return "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";
        else // Prod
            return "6LeF4SUUAAAAAJw3GHV8Im3MtIMZ5qj-NYVr5MGI";
    }

    private static GetSiteKeyV3(): string {
        if (window.location.hostname.indexOf("localhost") >= 0) // Running on Dev?
            return "6LcoYogoAAAAANqXldacaj4mhjLv40A1mjYqlea4";
        else // Prod
            return "6LdJMngUAAAAAHlBgDV88RiNeKI1ovrfnzCLsa4K";
    }

    // Note: Only expose promise object, not deferred object: https://medium.com/@codeliter/jquery-deferred-vs-promise-b3e13c00f921
    public promRecaptchaSetupDone = async () => await this.defRecaptchaSetupDone.promise();
    public promUserAccepted = async () => await this.defUserAccepted.promise();

    public GetUserPassedTest = () => this.UserPassedRecaptchaTest;

    // When user submits the form using recaptcha V3, get a new V3 token
    public async GetNewOneTimeV3TokenIfNeeded(): Promise<void> {
        if (!this.bRecaptchaV3Available)
            return;

        const Placeholder = (document.getElementById("reCaptchaV3Token") as HTMLInputElement).value;
        if (Placeholder !== "") { // recaptcha V3 is available?
            // Get new recaptcha V3 token and record into form so it can be passed to server for validation.
            const OneTimeV3Token = await grecaptcha.execute(MyRecaptchaHandler.GetSiteKeyV3(), { action: this.V3SubmittedAction });
            this.StoreV3Token(OneTimeV3Token);
        }
    }

    private StoreV3Token = (OneTimeV3Token: string) =>
        (document.getElementById("reCaptchaV3Token") as HTMLInputElement).value = OneTimeV3Token;
}
