import { ReportsBaseClass } from "../Lib/ReportsBaseClass"

export class Members extends ReportsBaseClass {
    constructor() {
        super('tableMembers', '/BTCAPI/Members/GetMembers');
        this.GetServerData();
    }

    ConvertRecordToTR(row: JQuery<HTMLTableRowElement>, record: {
        memberId: number, memberName: string, email: string, phone: string, address: string,
        lastDayOfMembership: string, notes: string
    }) {
        row.find('.spanName').html('<a href="/admin/editmember?RecordId=' + record.memberId + '">' + record.memberName + '</a>');
        row.find('.spanEmail').html("<a href='mailto:" + record.email + "'>" + record.email + "</a>");
        row.find('.spanPhone').html("<a href='tel:" + record.phone + "'>" + record.phone + "</a>");
        row.find('.spanAddress').html(record.address);

        row.find('.spanLastDayOfMembership').html(record.lastDayOfMembership);
        row.find('.spanNotes').html(record.notes);
    }

    QueryString() {
        let qFilters: string = '';

        qFilters += "&ActiveStatus=" + (document.querySelector('input[name=ActiveStatus]:checked') as HTMLInputElement).value;

        const MembersFilter: string = (document.getElementById('MembersFilter') as HTMLInputElement).value;
        if (MembersFilter !== '')
            qFilters += '&MemberName=' + encodeURI(MembersFilter);

        return qFilters;
    }
}
