import { ReportsBaseClass } from "../Lib/ReportsBaseClass";

export class Events extends ReportsBaseClass {
    constructor() {
        super('tableEvents', '/BTCAPI/Events/GetEvents');
        this.GetServerData();
    }

    ConvertRecordToTR(row: JQuery<HTMLTableRowElement>, record: {
        eventId: number, title: string, eventDate: string, eventTime: string,
        description: string
    }) {
        row.find('.spanTitle').html('<a href="/admin/editevent?RecordId=' + record.eventId + '">' + record.title + '</a>');
        row.find('.spanDate').html(record.eventDate);
        row.find('.spanTime').html(record.eventTime);
        row.find('.spanDescription').html(record.description);
    }

    QueryString() {
        let qFilters: string = '';

        qFilters += "&FutureEvents=" + (document.querySelector('input[name=FutureEvents]:checked') as HTMLInputElement).value;

        return qFilters;
    }
}
