import jQuery from "jquery";
import { EditRecordBase } from "../Lib/EditRecordBase";
import { ReportErrorToServer } from "../Lib/ReportErrorToServer";

export class EditRoleForm extends EditRecordBase {
    constructor(protected readonly FormId: string) {
        super(FormId, '/BTCAPI/Roles', '/admin/Roles');
        this.GetDataFromServer();
    }

    private async GetDataFromServer(): Promise<void> {
        if (this.RecordId > 0) {
            await this.GetRoleManagerCandidates();
            await this.GetRecord();
        }
    }

    private async GetRoleManagerCandidates(): Promise<void> {
        const getURL: string = '/BTCAPI/Roles/GetRoleManagerCandidates/' + this.RecordId;
        await jQuery.get(getURL, (JsonData: [{ memberName: string, memberId: number }]) => {
                const MembersSelect = document.getElementById('MemberId') as HTMLSelectElement;

                const optUnassigned = document.createElement('option');
                optUnassigned.value = "-1";
                optUnassigned.innerHTML = "- Unassigned -";
                optUnassigned.selected = true; // Select unassigned by default
                MembersSelect.appendChild(optUnassigned);

                // Add all active members to select element
                JsonData.forEach(m => {
                    const opt = document.createElement('option');
                    opt.value = m.memberId.toString();
                    opt.innerHTML = m.memberName;
                    MembersSelect.appendChild(opt);
                })
            })
            .fail(async (jqXHR, textStatus, errorThrown) => {
                await ReportErrorToServer.DisplayAndProcessAjaxFailure(getURL, "GET", jqXHR, textStatus, errorThrown, null, null)
            });
    }

    private async GetRecord(): Promise<void> {
        const getURL: string = '/BTCAPI/Roles/' + this.RecordId;
        await jQuery.get(getURL, (JsonData: { roleName: string, roleType: string, email: string, memberId: number }) => {
                (document.getElementById('RoleName') as HTMLInputElement).value = JsonData.roleName;
                (document.getElementById('Email') as HTMLInputElement).value = JsonData.email;
                (document.getElementById('RoleType') as HTMLSelectElement).value = JsonData.roleType;
                if (JsonData.memberId > 0)
                    (document.getElementById('MemberId') as HTMLSelectElement).value = JsonData.memberId.toString();
            })
            .fail(async (jqXHR, textStatus, errorThrown) => {
                await ReportErrorToServer.DisplayAndProcessAjaxFailure(getURL, "GET", jqXHR, textStatus, errorThrown, null, null)
            });
    }
}
