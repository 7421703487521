import {
    setPersistence, browserSessionPersistence,
    browserLocalPersistence, signInWithPopup, GoogleAuthProvider, TwitterAuthProvider, signInWithPhoneNumber, RecaptchaVerifier,
    ConfirmationResult, GithubAuthProvider, OAuthProvider, AuthProvider, User,
    Auth, getAuth
} from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { MyFirebaseConfig } from '../Lib/FirebaseConfig';
import { MyRecaptchaHandler } from "../Lib/ReCaptchaHandler";
import { ReportErrorToServer } from "../Lib/ReportErrorToServer";
import { PageBusyIndicator } from "../Lib/PageBusyIndicator";

// Added 11/18/2018 Google Firebase auth support from: https://console.firebase.google.com/u/0/project/btc-web/authentication/users
export class SignIn {
    private confirmationResultForPhone: ConfirmationResult | null = null;
    private recaptchaVerifierForPhone: RecaptchaVerifier | null = null;
    protected pageBusyIndicator = new PageBusyIndicator();
    protected readonly auth: Auth;
    protected myRecaptchaHandler: MyRecaptchaHandler = new MyRecaptchaHandler('SignInFormRendered', 'SignInFormSubmitted');

    constructor() {
        const app = initializeApp(MyFirebaseConfig);
        this.auth = getAuth(app);

        document.addEventListener("RecaptchaAcceptsUser", () => this.EnableDisableSubmit());

        this.pageBusyIndicator.Increment(); // Increment for recaptcha setup processing
        this.myRecaptchaHandler.promUserAccepted()
            .then(() => this.myRecaptchaHandler.promRecaptchaSetupDone())
            .then(() => {
                this.pageBusyIndicator.Decrement();
                this.EnableDisableSubmit();
            }); // recaptcha setup done

        // Sign-in by federated provider buttons
        document.getElementById("butSignInWithGoogle")?.addEventListener("click", () => this.SignInWithKnownProvider(new GoogleAuthProvider()));
        document.getElementById("butSignInWithGithub")?.addEventListener("click", () => this.SignInWithKnownProvider(new GithubAuthProvider()));
        document.getElementById("butSignInWithYahoo")?.addEventListener("click", () => this.SignInWithKnownProvider(new OAuthProvider('yahoo.com')));
        document.getElementById("butSignInWithXTwitter")?.addEventListener("click", () => this.SignInWithKnownProvider(new TwitterAuthProvider()));

        // Sign-in by phone buttons, one for code request and one for code verification
        document.getElementById("SignInWithPhone")?.addEventListener("click", () => this.SignInViaPhoneStep1());
        document.getElementById("SubmitSMSCode")?.addEventListener("click", () => this.ValidatePhoneSMSCodeStep2());

        // Sign-in by email verification button to send email
        document.getElementById("idEmailLinkLogin")?.addEventListener("click", () => this.LoginByEmailButtonClicked());

        for (const elem of Array.from(document.getElementsByClassName("SSOrbOption"))) // Add click event to radio buttons
            elem.addEventListener("click", () => this.ShowHideOptions());

        jQuery("#OptionsArea").show();

        // Make sure user is signed out in Firebase before wiring up onAuthStateChanged()
        this.auth.signOut().then(() => this.auth.onAuthStateChanged((user) => this.AuthStateChanged(user)));

        this.pageBusyIndicator.Decrement();
        this.EnableDisableSubmit();

        // Check if got here from email link sign-in request
        const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
        if (urlParams.has('LoginEmailAddress'))
            this.ValidateEmailLinkLoginRequest();
    }

    private EnableDisableSubmit = () => {
        const bRecaptchaTestRequiredAndFailed = this.myRecaptchaHandler !== null && !this.myRecaptchaHandler.GetUserPassedTest();
        jQuery('#idEmailLinkLogin').prop("disabled", bRecaptchaTestRequiredAndFailed || this.pageBusyIndicator.IsBusy())
    };

    private AuthStateChanged(user: User | null) { // Also fires on initial page load even if no state change.
        if (user !== null) { // User is logged-in in Firebase?
            user.getIdToken(true)
                .then((idToken: string) => this.LoginToBTCServer(idToken)) // Decode token: https://jwt.io/
                .catch((error) => {
                    ReportErrorToServer.ReportClientErrorToServer(error.message, "AuthStateChanged() in SignIn.ts");
                    jQuery('#ErrorMessage').html("An unexpected firebase sign-in error has occurred.");
                })
        }
    }

    private ShowHideOptions(): void {
        if ((document.getElementById("rbGoogleSignIn") as HTMLInputElement).checked)
            jQuery("#divGoogleSigin").show('fast');
        else
            jQuery("#divGoogleSigin").hide('fast');

        if ((document.getElementById("rbGitHubSignIn") as HTMLInputElement).checked)
            jQuery("#divGitHubSigin").show('fast');
        else
            jQuery("#divGitHubSigin").hide('fast');

        if ((document.getElementById("rbYahooSignIn") as HTMLInputElement).checked)
            jQuery("#divYahooSigin").show('fast');
        else
            jQuery("#divYahooSigin").hide('fast');

        if ((document.getElementById("rbXTwitterSignIn") as HTMLInputElement).checked)
            jQuery("#divXTwitterSigin").show('fast');
        else
            jQuery("#divXTwitterSigin").hide('fast');

        if ((document.getElementById("rbSMSSignIn") as HTMLInputElement).checked)
            jQuery("#divSMSSigin").show('fast');
        else
            jQuery("#divSMSSigin").hide('fast');

        if ((document.getElementById("rbEmailSignIn") as HTMLInputElement).checked)
            jQuery("#divEmailSigin").show('fast');
        else
            jQuery("#divEmailSigin").hide('fast');
    }

    private SignInWithKnownProvider(provider: AuthProvider) {
        this.pageBusyIndicator.Increment();
        jQuery('#ErrorMessage').html("");

        signInWithPopup(this.auth, provider) // If this succeeds, onAuthStateChanged() will be called
        .catch((error) => {
            jQuery('#ErrorMessage').html(error.code + "; Try again.");
        }
        ).finally(() => {
            this.pageBusyIndicator.Decrement()
        });
    }

    // For testing, use fictional phone #'s in Firebase Console (under Authentication | Sign-in Method | Phone | Phone #s for testing)
    private SignInViaPhoneStep1() {
        jQuery('#ErrorMessage').html("");

        const phoneNumRaw = (document.getElementById("PhoneNumber") as HTMLInputElement).value;
        const phoneNumDigits = phoneNumRaw.replace(/\D/g, '') // Remove non-digits
        if (phoneNumDigits.length !== 10) {
            jQuery('#ErrorMessage').html("Error: Only 10-digit USA phone #'s can be used.");
        }
        else {
            const E164Format = "+1" + phoneNumDigits; // Convert to E.164 format
            if (this.recaptchaVerifierForPhone === null)
                this.recaptchaVerifierForPhone = new RecaptchaVerifier(this.auth, 'MySMS-recaptcha-container', {});

            this.pageBusyIndicator.Increment();
            jQuery("#SignInWithPhone").hide(); // Hide phone # submit button while user processes recaptcha V2
            signInWithPhoneNumber(this.auth, E164Format, this.recaptchaVerifierForPhone)
                .then((confirmationResult) => {
                    this.confirmationResultForPhone = confirmationResult;
                    jQuery("#divSMSCode").show('slow');
                }).catch((error) => { // Error; SMS not sent
                    jQuery('#ErrorMessage').html(error.message);
                }).finally(() => {
                    this.pageBusyIndicator.Decrement();
                    this.recaptchaVerifierForPhone?.clear();
                    this.recaptchaVerifierForPhone = null;
                });
        }
    }

    private ValidatePhoneSMSCodeStep2() {
        this.pageBusyIndicator.Increment();

        const inputCode = document.getElementById("SMSCode") as HTMLInputElement;
        const code = inputCode.value;
        this.confirmationResultForPhone?.confirm(code)  // If this succeeds, onAuthStateChanged() will be called
            .catch((error) => {
                if (error.code === "auth/invalid-verification-code")
                    jQuery('#ErrorMessage').html("Code could not be verified; try again");
                else
                    jQuery('#ErrorMessage').html(error.message);
            }).finally(() => {
                this.pageBusyIndicator.Decrement();
                inputCode.value = "";
                jQuery("#SignInWithPhone").show();
            });
    }

    private LoginToBTCServer(idToken: string) {
        jQuery('#ErrorMessage').html('');

        this.pageBusyIndicator.Increment();
        const cbStaySignedIn: HTMLInputElement = document.getElementById('StaySignedIn') as HTMLInputElement;

        if (cbStaySignedIn.checked)
            setPersistence(this.auth, browserLocalPersistence); // https://firebase.google.com/docs/auth/web/auth-state-persistence
        else
            setPersistence(this.auth, browserSessionPersistence);

        // Note: When sending oData (not json) to server (via POST), Asp.Net core action's dto in function
        // signature must be marked [FromForm].
        // Otherwise, when sending json to server, then on client, set data: JSON.Stringify(data) and set
        // contentType: "application/json"
        const oData = {
            IdToken: idToken,
            StaySignedIn: cbStaySignedIn.checked ? "true" : "false",
        };

        const URL = '/BTCAPI/SignInSignOut/FirebaseSignin';
        jQuery.post(
            URL,
            oData,
            async () => {
                let redirectURL: string = '/'; // Go to home page
                const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
                if (urlParams.has('Redirect'))
                    redirectURL = urlParams.get('Redirect')!; // "!" suppresses warning on possible null.
                window.location.href = redirectURL;  // Force this page to reload so the menu widgets on left side will adjust to logged in status.
            })
            .fail(async (jqXHR, textStatus, errorThrown) =>
                await ReportErrorToServer.DisplayAndProcessAjaxFailure(URL, "POST", jqXHR, textStatus,
                    errorThrown, null, oData))
            .always(() => {
                this.pageBusyIndicator.Decrement();
                this.EnableDisableSubmit();
            })
            ;
    }



    ///////////////////////////// BTC Sign-in by Email Verification (doesn't use Firebase) //////////////////////////////////

    private async LoginByEmailButtonClicked(): Promise<void> {
        if (this.myRecaptchaHandler !== null)
            await this.myRecaptchaHandler.GetNewOneTimeV3TokenIfNeeded();

        this.pageBusyIndicator.Increment();
        jQuery('#ErrorMessage').html('');
        this.EnableDisableSubmit(); // Disable submit button while form is being processed by Ajax.

        let redirectURL: string = '/'; // Go to home page
        const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
        if (urlParams.has('Redirect'))
            redirectURL = urlParams.get('Redirect')!; // "!" suppresses warning on possible null.

        const oData = {
            email: (document.getElementById('UserEmailForLink') as HTMLInputElement)?.value ?? "",
            StaySignedIn: (document.getElementById('StaySignedIn') as HTMLInputElement).checked ? "true" : "false",
            Redirect: redirectURL,
            reCaptchaV3Token: (document.getElementById('reCaptchaV3Token') as HTMLInputElement)?.value ?? ""
        };
        const URL = '/BTCAPI/SignInSignOut/RequestSignInEmail';
        jQuery.post(
            URL,
            oData,
            () => jQuery("#EmailSentMsg").fadeIn()
        )
        .fail(async (jqXHR, textStatus, errorThrown) =>
            await ReportErrorToServer.DisplayAndProcessAjaxFailure(URL, "POST", jqXHR, textStatus,
                errorThrown, null, oData))
        .always(() => {
            this.pageBusyIndicator.Decrement();
            this.EnableDisableSubmit();
        });
    }

    // Verify with server if the sign-in email link request is legit
    private ValidateEmailLinkLoginRequest() {
        const urlParams: URLSearchParams = new URLSearchParams(window.location.search);

        const HttpVerb = "GET";
        const URL = '/BTCAPI/SignInSignOut/SignInFromEmailLink?LoginEmailAddress=' + urlParams.get('LoginEmailAddress')! +
            '&EmailLinkLoginSecret=' + urlParams.get('secret')! + '&StaySignedIn=' + urlParams.get('StaySignedIn')!;

        jQuery.get(URL)
            .done(
                () => window.location.href = urlParams.get('Redirect')! // Force reload to refresh menu
            )
            .fail(async (jqXHR, textStatus, errorThrown) =>
                await ReportErrorToServer.DisplayAndProcessAjaxFailure(URL, HttpVerb, jqXHR, textStatus,
                    errorThrown, null, null))
            .always(() => {
                this.pageBusyIndicator.Decrement();
                this.EnableDisableSubmit();
            })
            ;

    }
}
