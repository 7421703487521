import jQuery from "jquery";
import { EditRecordBase } from "../Lib/EditRecordBase";
import { PageBusyIndicator } from "../Lib/PageBusyIndicator";
import { ReportErrorToServer } from "../Lib/ReportErrorToServer";

export class HomePageContactUsForm extends EditRecordBase {
    constructor(protected readonly FormId: string, protected pageBusyIndicator: PageBusyIndicator) {
        super(FormId, '/BTCAPI/ContactUs', '', false, pageBusyIndicator);

        this.SignedInMemberInfo().then(async (Member) => {
            if (Member.memberId <= 0) // Not signed-in; Only not-logged in users must pass recaptcha test
                await this.SetupRecaptcha('Checkout', 'Checkout');
            else {
                (document.getElementById('FirstName') as HTMLInputElement).value = Member.firstName;
                (document.getElementById('LastName') as HTMLInputElement).value = Member.lastName;
                (document.getElementById('Email') as HTMLInputElement).value = Member.email;
            }
            this.pageBusyIndicator.Decrement();
        })
    }
}
