import { ReportsBaseClass } from "../Lib/ReportsBaseClass";

export class Products extends ReportsBaseClass {
    constructor() {
        super('tableEvents', '/BTCAPI/Products/GetProducts');
        this.GetServerData();
    }

    protected override ServerCallDone(dtoProds: ProdInfo[]) {
        const tbody = (document.getElementById('tableProds') as HTMLTableElement).getElementsByTagName('tbody')[0];
        tbody.innerHTML = ""; // Remove any prior rows (but keep the headers)
        dtoProds.forEach(prod => {
            const rowProd = tbody.insertRow(tbody.rows.length);
            rowProd.innerHTML =
                `<td><a href="/admin/EditProduct?RecordId=${prod.prodId}">${prod.prodName}</a></td>`
                + `<td>${prod.description}</td>`
                + `<td class="TextAlign_Right">${prod.price === null ? "" : "$" + prod.price.toFixed(2)}</td>`
                + `<td>${prod.membershipTimeframe ?? ""}</td>`
                + `<td>${prod.membershipTimeframe === null ? "" : (prod.isNewMembership ? "New" : "Renew")}</td>`
                ;
        })
        jQuery('#NumRecords').text(dtoProds.length + ' result' + (dtoProds.length === 1 ? '' : 's')).show();
    }

    QueryString() {
        let qFilters: string = '';

        qFilters += "&Active=" + (document.querySelector('input[name=ActiveProducts]:checked') as HTMLInputElement).value;

        return qFilters;
    }
}
