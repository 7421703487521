import { initializeApp } from 'firebase/app';
import { Auth, getAuth } from 'firebase/auth';
import { ReportErrorToServer } from "../Lib/ReportErrorToServer";
import { MyFirebaseConfig } from '../Lib/FirebaseConfig';
import { PageBusyIndicator } from "../Lib/PageBusyIndicator";

export class SignOut{
    protected pageBusyIndicator = new PageBusyIndicator();
    protected readonly auth: Auth;

    constructor() {
        const app = initializeApp(MyFirebaseConfig);
        this.auth = getAuth(app);

        document.getElementById("SignOutLink")?.addEventListener("click", async () => await this.DoSignOut());
        this.pageBusyIndicator.Decrement();
    }

    private async DoSignOut() : Promise<void> {
        this.pageBusyIndicator.Increment();

        await this.auth.signOut(); // Tell firebase that user is signing out

        // Tell server that user is signing out
        const SignOutPostURL = '/BTCAPI/SignInSignOut/SignOut';
        await jQuery.post(SignOutPostURL)
            .fail(async (jqXHR, textStatus, errorThrown) => {
                await ReportErrorToServer.DisplayAndProcessAjaxFailure(SignOutPostURL, "POST", jqXHR, textStatus, errorThrown, null, null);
            })
        this.pageBusyIndicator.Decrement();
        window.location.href = '/' // Redirect to home page
    }
}
