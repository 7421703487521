import jQuery from "jquery";
import { EditRecordBase } from "../Lib/EditRecordBase";
import { ReportErrorToServer } from "../Lib/ReportErrorToServer";

export class EditRoleMembers extends EditRecordBase {
    constructor(protected readonly FormId: string) {
        super(FormId, "/BTCAPI/Roles/AssignGroupMembers/");
        this.GetDataFromServer();
    }

    private async GetDataFromServer(): Promise<void> {
        EditRecordBase.pageBusyIndicator.Increment();
        await this.LoadMembersList();
        this.SetupCheckBoxClickHandlers();
        await this.GetRecordFromServer();
        EditRecordBase.pageBusyIndicator.Decrement();
    }

    private async LoadMembersList(): Promise<void> {
        const getURL: string = '/BTCAPI/Roles/GetAllMembers/' + this.RecordId;
        await jQuery.get(getURL, (JsonData: [{ memberName: string, memberId: number, inGroup: boolean, active: boolean }]) => {
                const ul = document.getElementById("ulRoleMembers") as HTMLUListElement;
                JsonData.forEach(member => {
                    // Make a checkbox for this member
                    const cb = document.createElement('input') as HTMLInputElement;
                    cb.type = "checkbox";
                    cb.name = "cbMember[]";
                    cb.id = "cbMemberId-" + member.memberId;
                    cb.value = member.memberId.toString();
                    cb.checked = member.inGroup;

                    // Make a label for the checkbox
                    const label = document.createElement("label") as HTMLLabelElement;
                    label.textContent = member.memberName;
                    label.htmlFor = cb.id;
                    if (member.inGroup && !member.active) // Inactive member in group? Add class to highlight
                        label.classList.add("InactiveGroupMember");

                    // Make LI, add checkbox and label to it
                    const li = document.createElement("li") as HTMLLIElement;
                    li.classList.add(member.inGroup ? "InGroup" : "NotInGroup"); // Add class to show/hide LIs
                    li.append(cb);
                    li.append(label);

                    // Add built LI to UL
                    ul.append(li);
                });

            })
            .fail(async (jqXHR, textStatus, errorThrown) => {
                await ReportErrorToServer.DisplayAndProcessAjaxFailure(getURL, "GET", jqXHR, textStatus, errorThrown, null, null)
            });
    }

    private SetupCheckBoxClickHandlers(): void {
        // To each member's checkbox, add change event to send that member's new status to server
        jQuery('input[type=checkbox][name=cbMember\\[\\]]').each((_, cbMember) => {
            cbMember.addEventListener('change', async (evt) => {
                this.UpdateLIClassAssignment(evt.currentTarget as HTMLInputElement); // Update screen to show/hide check box in current list.
                await this.DoSubmit();// Immediately save changes to DB.
            });
        });

        // Setup radio button filters.
        jQuery('input[type=radio][name=ShowMembers]').each(
            (_, rb: HTMLElement) => rb.addEventListener('change', () => this.ShowHideAllLIs()));

        this.ShowHideAllLIs()
    }

    private async GetRecordFromServer(): Promise<void> {
        const getURL: string = '/BTCAPI/Roles/' + this.RecordId;
        await jQuery.get(getURL, (JsonData: { roleName: string }) => {
                (document.getElementById('idRoleName') as HTMLLabelElement).textContent = JsonData.roleName;
            })
            .fail(async (jqXHR, textStatus, errorThrown) => {
                await ReportErrorToServer.DisplayAndProcessAjaxFailure(getURL, "GET", jqXHR, textStatus, errorThrown, null, null)
            });
    }

    private ShowHideAllLIs() {
        jQuery('#ulRoleMembers > LI').each((n: number, li: HTMLElement) => this.ShowHideLI(li as HTMLLIElement));
    }

    private UpdateLIClassAssignment(cb: HTMLInputElement) {
        const LI: HTMLLIElement = cb.parentElement as HTMLLIElement;
        if (cb.checked && LI.className === 'NotInGroup')
            LI.className = 'InGroup';
        else if (!cb.checked && LI.className === 'InGroup')
            LI.className = 'NotInGroup';

        this.ShowHideLI(LI);
    }

    private ShowHideLI(LI: HTMLLIElement) {
        const FilterValue: string = (document.querySelector('input[type=radio][name=ShowMembers]:checked') as HTMLInputElement).value;

        let newDisplay: string = "none";
        if (FilterValue === 'AllMembers' || FilterValue === LI.className)
            newDisplay = "inline-block";

        LI.style.display = newDisplay;
    }
}
