
export class ReportErrorToServer {
    public static readonly BAD_REQUEST: number = 400; // Sent by ASP.Net Core when property validation fails (eg [Required(ErrorMessage = "Please enter a Role Name")])
    public static readonly UNAUTHORIZED: number = 401; // User is unknown
    public static readonly FORBIDDEN: number = 403; // User is known, but not authorized
    public static readonly UNPROCESSABLE_ENTITY: number = 422; // Unprocessable Entity
    public static readonly SERVICE_UNAVAILABLE: number = 503;
    public static readonly SERVER_SIDE_RECAPTCHA_FAILED: number = 550;
    public static readonly BTCAPI_EXCEPTION: number = 552;

    // From: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Using_promises#creating_a_promise_around_an_old_callback_api
    private static sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

    public static async ReportClientErrorToServer(errorMessage: string, errorFrom: string, error: unknown | null = null) {
        jQuery.post(
            "/BTCAPI/ReportClientSideError/ClientError",
            {
                errorFrom: errorFrom,
                errorMessage: errorMessage ?? '',
                error: (error instanceof Error ? error.message : (error instanceof String ? error as String : "")),
                errorStack: (error instanceof Error ? error.stack : ""),
            });

        await this.sleep(2000); // Short throttle incase this is happening inside a loop.
    }

    // Analyze ajax error and determine whether to show to end user and if need to
    // request unexpected error notification email from server
    public static async DisplayAndProcessAjaxFailure (
        PostToAPIUrl: string,
        HttpVerb: string,
        jqXHR: JQuery.jqXHR<any>,
        textStatus: JQuery.Ajax.ErrorTextStatus,
        errorThrown: string,
        formData: FormData | null,
        oKVP: object | null // object as key value pairs (eg: { name: "John", age: 30, city: "New York" })
    ): Promise<void> {
        let ErrMsg = "";

        // Stringify form data, if any
        let sFormInfo: string = "";
        if (formData !== null)
            for (var pair of formData.entries())
                sFormInfo += pair[0] + ": " + pair[1] + "; ";

        // Stringify object data, if any
        let sObjectData: string = "";
        if (oKVP !== null)
            sObjectData += JSON.stringify(oKVP);

        if (jqXHR.status === 0) // No response from server?
            ErrMsg = "The BTC server is not responding. Either it's inaccessible/offline or your internet connection is down.";
        else if (jqXHR.status === ReportErrorToServer.SERVICE_UNAVAILABLE) // Apache web server is working, but ASP.Net Core web API isn't responding
            ErrMsg = "The BTC web API is offline";
        else if (jqXHR.status === ReportErrorToServer.BAD_REQUEST) // Probably a model validation failed in ASP.Net Core
            for (let errObj in jqXHR.responseJSON.errors)
                ErrMsg += (ErrMsg !== "" ? "<br>" : "") + jqXHR.responseJSON.errors[errObj];
        else if (jqXHR.status === ReportErrorToServer.UNAUTHORIZED) {
            const PageAddr = window.location.href.toString().split(window.location.host)[1];
            ErrMsg = "Unauthorized. You need to <a href='/SignIn?Redirect=" + PageAddr + "'>sign-in</a>";
        }
        else if (jqXHR.status === ReportErrorToServer.FORBIDDEN) {
            //ErrMsg = "You're logged in, but don't have the correct authorization to access this page";
            ErrMsg = jqXHR.responseJSON.detail;
        }
        else if (jqXHR.status === ReportErrorToServer.UNPROCESSABLE_ENTITY)  // Business rule violation (eg Duplicate record exists)
            for (let errObj in jqXHR.responseJSON)
                (jqXHR.responseJSON[errObj] as Array<string>).forEach(item => ErrMsg += (ErrMsg !== "" ? "<br>" : "") + item);
        else if (jqXHR.status === ReportErrorToServer.SERVER_SIDE_RECAPTCHA_FAILED) // Have a basic ProblemDetails message (eg auth failure)
            ErrMsg = "Recaptcha validation failed 😟; Google thinks you're a bot 🤖";
        else if (jqXHR.status === ReportErrorToServer.BTCAPI_EXCEPTION) {
            // eg ProblemDetails with string error msg or possibly an exception in json for Dev only (eg sql syntax error)
            try {
                // Server might put a plain string or a json string in the detail field
                const json = JSON.parse(jqXHR.responseJSON.detail); // This will throw if detail is not a valid json string
                if (Array.isArray(json)) {
                    for (let exIndex in json) { // Loop through array of exceptions
                        ErrMsg += "<table border='1'>"
                        const ex = json[exIndex]; // Get current exception
                        for (let exDataName in ex) // Loop through exeption items
                            ErrMsg += "<tr><td>" + exDataName + "</td><td>" + (ex[exDataName] as string).replace("\n", "<br>\n") + "</td></tr>";
                        ErrMsg += "</table><br><br>"
                    }
                }
                else
                    ErrMsg += jqXHR.responseJSON.detail; // json isn't an array; server returned unexpected json
            }
            catch {
                ErrMsg += jqXHR.responseJSON.detail; // detail isn't a json string; server returned unexpected non-json data
            }
        }
        else { // Unexpected error, so send email
            ErrMsg = "Unexpected server error; The Powers That Be have been notified (possibly)";

            // TODO: Reinstate this one email when stopping emailing all failures
            //await this.ReportAjaxFailToServer(ErrMsg, PostToAPIUrl, HttpVerb, jqXHR, textStatus, errorThrown, "", sFormInfo, sObjectData);

            await this.sleep(2000); // Short throttle incase this is happening inside a loop.
        }

        // TODO: Temporarily email all failures
        await this.ReportAjaxFailToServer(ErrMsg, PostToAPIUrl, HttpVerb, jqXHR, textStatus, errorThrown, "", sFormInfo, sObjectData);

        jQuery('#ErrorMessage').html(ErrMsg);
    }

    private static async ReportAjaxFailToServer(EndUserErrMsg: string, requestURL: string, HttpVerb: string, jqXHR: JQuery.jqXHR<any>,
        TextStatus: JQuery.Ajax.ErrorTextStatus | null, errorThrown: string, FromFunction: string,
        sFormInfo: string, sObjectData: string) {

        // Write error to console
        let TruncatedResponseText = "";
        if (jqXHR.responseText !== undefined)
            TruncatedResponseText = jqXHR.responseText.length > 2000 ? jqXHR.responseText.substring(0, 2000) : jqXHR.responseText;

        if (console && console.log) {
            const ErrMsgForConsole: string =
                "End User Msg: " + EndUserErrMsg
                + "; Status: " + TextStatus
                + "; jqXHR.statusText:" + jqXHR.statusText
                + "; jqXHR.responseText: " + TruncatedResponseText
                + "; jqXHR.readyState: " + jqXHR.readyState
                + "; errorThrown: " + errorThrown
                + "; FromFunction: " + FromFunction
                + "; FormParameters: " + sFormInfo
                + "; ObjectData: " + sObjectData
                ;
            console.log(ErrMsgForConsole);
        }

        // Send error details to server to send error email
        jQuery.post(
            "/BTCAPI/ReportClientSideError/AjaxFailure",
            {
                CurrentWebPage: window.location.href,
                EndUserErrMsg: EndUserErrMsg,
                HttpVerb: HttpVerb,
                TextStatus: TextStatus,
                ErrorThrown: errorThrown,
                RequestURL: requestURL,
                FromFunction: FromFunction,
                FormParameters: sFormInfo,
                ObjectData: sObjectData,

                StatusText: jqXHR.statusText,
                TruncatedResponseText: TruncatedResponseText,
                ResponseJSON: JSON.stringify(jqXHR.responseJSON),
            }
        );
    }
}
