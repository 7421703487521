interface BTCCartItem {
    GuidKey: string, ProdId: number, ProdName: string, ProdDesc: string, UnitPrice: number, Qty: number, RecordId: number,
    Category: string | null, Message: string | null
};

export class CartCookie {
    private static readonly BTCCartCookieName = "BTCCart"; // Cart name must match server's cart name

    constructor(protected readonly divCartId: string) {
        this.ShowHideCartIcon();
    }

    // Show the cart's total amount icon on all pages except the check out page
    private ShowHideCartIcon(): void
    {
        const cartitems = CartCookie.GetBTCCartCookie()
        if (cartitems !== null) {
            let bInCheckoutPage: boolean = location.pathname.toLowerCase().indexOf("/btccheckout", 0) >= 0;
            if (cartitems.length > 0 && !bInCheckoutPage) {
                let CartTotal = 0;
                cartitems.forEach((cartitem) => {
                    CartTotal += cartitem.UnitPrice * cartitem.Qty;
                });
                (document.getElementById("spanCartTotal") as HTMLSpanElement).innerText = "$" + CartTotal.toFixed(2);

                jQuery('.divBTCHeaderCart').slideDown(500);
            }
        }
    }

    // Delete a cart item by its guid
    public static DeleteCartItem(GuidToDelete: string): void {
        const nameEQ = this.BTCCartCookieName + "=";

        let NewCookies: BTCCartItem[] = [];
        const cookies = document.cookie.split(';');
        cookies.forEach(cookie => { // Iterate over all cookies to find cart cookie
            cookie = cookie.trim(); // Remove leading space from above split()
            if (cookie.indexOf(nameEQ) === 0) { // Is this the cart cookie?
                const sRawCookieValue = cookie.substring(nameEQ.length); // Strip off cookie name leaving cookie value
                const sDecodedCookieValue = decodeURIComponent(sRawCookieValue);
                const cartitems = JSON.parse(sDecodedCookieValue) as [BTCCartItem]; // Decode json to array of BTCCartItem
                cartitems.forEach(item => {
                    if (item.GuidKey !== GuidToDelete)
                        NewCookies.push(item);
                })
            }
        })

        // Write new cookie without deleted item
        // Note: The format of this cookie must match how ASP.Net Core expects the cookies (json, uri encoded)
        const date = new Date();
        const days = 7;
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        const expires = "; expires=" + date.toUTCString();
        let sNewCookiesJsonEncoded = encodeURIComponent(JSON.stringify(NewCookies));
        document.cookie = CartCookie.BTCCartCookieName + "=" + sNewCookiesJsonEncoded + expires +
            "; path=/; Secure; IsEssential; SameSite=Strict";
    }

    // Get BTC cart cookie's data
    public static GetBTCCartCookie(): [BTCCartItem] | null {
        const nameEQ = this.BTCCartCookieName + "=";

        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) { // Iterate over all cookies to find required cookie by name
            let cookie = cookies[i].trim();
            if (cookie.indexOf(nameEQ) === 0) { // found requested cookie?
                const sRawCookieValue = cookie.substring(nameEQ.length, cookie.length);
                const sDecodedCookieValue = decodeURIComponent(sRawCookieValue);
                const cartitems = JSON.parse(sDecodedCookieValue) as [BTCCartItem]; // Decode json to array of BTCCartItem
                return cartitems;
            }
        }
        return null;
    }
}
