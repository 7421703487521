import jQuery from "jquery";
import { EditRecordBase } from "../Lib/EditRecordBase";
import { ReportErrorToServer } from "../Lib/ReportErrorToServer";

export class EditMyAcctForm extends EditRecordBase {
    constructor(protected readonly FormId: string) {
        super(FormId, '/BTCAPI/Members/MyRecord');
        this.GetDataFromServer();
    }

    protected override CalcHttpVerb = (): string => "PUT"; // PUT only (never POST) record of currently signed-in user only

    private async GetDataFromServer(): Promise<void> {
        const getURL: string = '/BTCAPI/Members/GetMyAcct?From=EditMyAcctForm';
            await jQuery.get(getURL, (JsonData: {
                firstName: string, lastName: string, phoneRaw: string, gender: string,
                email: string, address: string, apt: string, city: string, state: string,
                zip: string, country: string, shirtSize: string, lastDayOfMembership: string, active: boolean
            }) => {
                (document.getElementById('FirstName') as HTMLInputElement).value = JsonData.firstName;
                (document.getElementById('LastName') as HTMLInputElement).value = JsonData.lastName;
                (document.getElementById('Phone') as HTMLInputElement).value = JsonData.phoneRaw;
                (document.getElementById('Gender') as HTMLSelectElement).value = JsonData.gender;
                (document.getElementById('Email') as HTMLInputElement).value = JsonData.email;
                (document.getElementById('Address') as HTMLInputElement).value = JsonData.address;
                (document.getElementById('Apt') as HTMLInputElement).value = JsonData.apt;
                (document.getElementById('City') as HTMLInputElement).value = JsonData.city;
                (document.getElementById('State') as HTMLInputElement).value = JsonData.state;
                (document.getElementById('Zip') as HTMLInputElement).value = JsonData.zip;
                (document.getElementById('Country') as HTMLInputElement).value = JsonData.country;
                (document.getElementById('ShirtSize') as HTMLSelectElement).value = JsonData.shirtSize;
                (document.getElementById('LastDayOfMembership') as HTMLLabelElement).textContent = JsonData.lastDayOfMembership;
                (document.getElementById('Status') as HTMLLabelElement).textContent = JsonData.active ? "Active" : "Not active";
            })
                .fail(async (jqXHR, textStatus, errorThrown) => {
                    await ReportErrorToServer.DisplayAndProcessAjaxFailure(getURL, "GET", jqXHR, textStatus, errorThrown, null, null)
                });
        }
}
