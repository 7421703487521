import jQuery from "jquery";
import { EditRecordBase } from "../Lib/EditRecordBase";

export class JoinRenewForm extends EditRecordBase {
    private PageTitle: string = "Join";
    private MembershipProds!: ProdInfo[]; // MembershipProds will be initialized in the ctor

    constructor(protected readonly FormId: string) {
        super(FormId, '/BTCAPI/JoinRenew/JoinRenew', '/BTCCheckout/');

        document.getElementById('selectMembershipType')?.addEventListener('change', () => this.ShowHideNumMonths());

        const DonationAmountOther: HTMLInputElement = document.getElementById('DonationOtherAmount') as HTMLInputElement;
        jQuery('input[name="DonationPreSetAmt"]').each((_, rb) =>
            rb.addEventListener('change', (event: Event) =>
                DonationAmountOther.disabled = (event.currentTarget as HTMLInputElement).value !== 'Other'));
        DonationAmountOther.disabled = true;

        jQuery.get('/BTCAPI/Products/GetProducts?Active=Yes&ProdType=Memberships')
            .then((prodinfo: ProdInfo[]) => {
                this.MembershipProds = prodinfo;
                this.SignedInMemberInfo()
                    .then(async (member) => {
                        if (member.memberId <= 0) { // Only not-logged in users must pass recaptcha test
                            await this.SetupRecaptcha('JoinRenewFormRendered', 'JoinRenewFormSubmitted');

                            (document.getElementById("pExistingtMemberSignIn") as HTMLParagraphElement).style.display = "block";
                            (document.getElementById("divMembershipBenefits") as HTMLParagraphElement).style.display = "block";
                            (document.getElementById("idNoJerseyForShortTerm") as HTMLParagraphElement).style.display = "block";

                            this.WritePrices(true);
                            this.SetupMembershipTypeDropdown(true);
                            (document.getElementById('formJoinRenewEditRecord') as HTMLFormElement).style.display = "block"; // Main form
                        }
                        else {
                            // Logged-in users can't edit their names
                            (document.getElementById("FirstName") as HTMLInputElement).readOnly = true;
                            (document.getElementById("LastName") as HTMLInputElement).readOnly = true;
                            (document.getElementById("FirstName") as HTMLInputElement).classList.add("readonlyInput");
                            (document.getElementById("LastName") as HTMLInputElement).classList.add("readonlyInput");

                            this.PopulateForm(member);
                            this.WritePrices(false);
                            this.SetupMembershipTypeDropdown(false);
                            this.ShowContentForKnownMember(member.lastDayOfMembership);
                            if (member.tooSoonToRenew)
                                (document.getElementById('divTooEarlyToRenew') as HTMLDivElement).style.display = "block"; // Show "too early" msg
                            else
                                (document.getElementById('formJoinRenewEditRecord') as HTMLFormElement).style.display = "block"; // Main form
                        }
                    }).finally(() => {
                        (document.getElementById('idPricesTable') as HTMLTableElement).style.display = "block"; // Price schedule table
                        (document.getElementById("spanPageTitle") as HTMLSpanElement).innerText = this.PageTitle;
                        this.ShowHideNumMonths();
                    });
            });
    }

    private ShowHideNumMonths() {
        const MembershipProdId: number = Number(jQuery('#selectMembershipType option:selected').val());
        const Prod = this.MembershipProds.find(prod => prod.prodId === MembershipProdId);
        if (Prod !== undefined && Prod.membershipTimeframe === "Month") // Is monthly type of membership?
            jQuery('#divMembershipMonths').show('slow');
        else
            jQuery('#divMembershipMonths').hide('slow'); // Not monthly, so hide month dropdown
    }

    private ShowContentForKnownMember(lastDayOfMembership: string): void {
        this.PageTitle = "Renew";
        (document.getElementById('TooEarlyExpDate') as HTMLSpanElement).innerText = lastDayOfMembership;
        (document.getElementById('ExpDate') as HTMLSpanElement).innerText = lastDayOfMembership;
        (document.getElementById("pRequestAcctUpdate") as HTMLParagraphElement).style.display = "block";
        (document.getElementById("divExpirationDateNote") as HTMLDivElement).style.display = "block";
    }

    private WritePrices(IsNewMember: boolean): void {
        let Prods = this.MembershipProds.filter(prod => prod.isNewMembership === IsNewMember);
        const tbody = (document.getElementById('idPricesTable') as HTMLTableElement).getElementsByTagName('tbody')[0];
        Prods.forEach(prod => {
            const newRow = tbody.insertRow(tbody.rows.length);
            newRow.innerHTML = `<td>${prod.prodName} (${prod.description})</td><td>$${prod.price.toFixed(2)}/${prod.membershipTimeframe}</td>`;
        })
    }

    private SetupMembershipTypeDropdown(IsNewMember: boolean): void {
        const select = document.getElementById("selectMembershipType") as HTMLSelectElement;

        // Get only product choices appropriate for new members or renewing members
        let Prods = this.MembershipProds.filter(prod => prod.isNewMembership === IsNewMember);
        Prods.forEach(prod => {
            const o1 = document.createElement('option') as HTMLOptionElement;
            o1.value = prod.prodId.toString();
            o1.textContent = `${prod.prodName} (${prod.description})`;
            select.append(o1);
        })
    }

    private PopulateForm(JsonData: MemberInfo): void {
        (document.getElementById('FirstName') as HTMLInputElement).value = JsonData.firstName;
        (document.getElementById('LastName') as HTMLInputElement).value = JsonData.lastName;
        (document.getElementById('Email') as HTMLInputElement).value = JsonData.email;
        (document.getElementById('Phone') as HTMLInputElement).value = JsonData.phoneRaw;

        (document.getElementById('Address') as HTMLInputElement).value = JsonData.address;
        (document.getElementById('Apt') as HTMLInputElement).value = JsonData.apt;
        (document.getElementById('City') as HTMLInputElement).value = JsonData.city;
        (document.getElementById('State') as HTMLInputElement).value = JsonData.state;
        (document.getElementById('Zip') as HTMLInputElement).value = JsonData.zip;
        (document.getElementById('Country') as HTMLInputElement).value = JsonData.country;

        (document.getElementById('Gender') as HTMLSelectElement).value = JsonData.gender;
        (document.getElementById('ShirtSize') as HTMLSelectElement).value = JsonData.shirtSize;
    }
}
