import { ReportsBaseClass } from "../Lib/ReportsBaseClass";

interface OrderInfo {
    orderId: number, created: string, payerName: string, email: string, notes: string,
    paymentGatewayOrderNum: string, prodName: string, prodDesc: string, unitPrice: number, qty: number,
    registeredMemberName: string, buyerMsg: string
}

export class Orders extends ReportsBaseClass {
    constructor() {
        super('tableEvents', '/BTCAPI/Orders/GetOrders');
        this.LoadYearsSelect();
        this.GetServerData();
    }

    LoadYearsSelect() {
        const YearsSelect: HTMLSelectElement = document.getElementById('idYears') as HTMLSelectElement;

        const EarliestYear: number = 2013;
        const CurrentYear: number = (new Date()).getFullYear();
        for (let nYear: number = CurrentYear; nYear >= EarliestYear; nYear--) // Years in reverse order
            YearsSelect.add(new Option(nYear.toString(), nYear.toString()));

        YearsSelect.selectedIndex = 1; // Not index zero since that's "All Years"
    }

    protected override ServerCallDone(dtoOrders: OrderInfo[]) {
        const tbody = (document.getElementById('idTableOrders') as HTMLTableElement).getElementsByTagName('tbody')[0];
        tbody.innerHTML = ""; // Remove any prior rows (but keep the headers)
        dtoOrders.forEach(order => {
            const rowOrder = tbody.insertRow(tbody.rows.length);
            rowOrder.innerHTML =
                `<td>${order.orderId}</td>`
                + `<td>${order.created}</td>`
                + `<td>${order.payerName}</td>`
                + `<td>${order.email}</td>`
                + `<td>${order.prodName}</td>`
                + `<td>${order.prodDesc}</td>`
                + `<td class="TextAlign_Right">$${(order.unitPrice * order.qty).toFixed(2)}</td>`
            + `<td>${order.registeredMemberName.length !== 0 ? "For: " + order.registeredMemberName : order.buyerMsg}</td>`
                + `<td>${order.paymentGatewayOrderNum}</td>`
                ;
        })
        jQuery('#NumRecords').text(dtoOrders.length + ' result' + (dtoOrders.length === 1 ? '' : 's')).show();
    }

    QueryString() {
        let qFilters: string = '';

        qFilters += 'Year=' + parseInt((document.getElementById('idYears') as HTMLSelectElement).value);

        const PayersFilter: string = (document.getElementById('idPayersFilter') as HTMLInputElement).value;
        if (PayersFilter !== '')
            qFilters += '&PayerName=' + encodeURI(PayersFilter);

        const ProdType = (document.querySelector('input[name="ProdType"]:checked') as HTMLInputElement)?.value;
        qFilters += '&ProdType=' + encodeURI(ProdType);

        return qFilters;
    }
}
