import jQuery from "jquery";
import { PageBusyIndicator } from "../Lib/PageBusyIndicator";

interface SundayRun {
    runTitle: string,
    miles: string,
    locationName: string,
    locationMapURL: string,
    courseMapURL: string,
    courseDescription: string,
    drinkSupportMethod: string,
    drinkNotes: string,
    miscNotes: string,

    strTargetDate: string,
    targetDate: string,
    startTime: string,
    isPrivate: boolean
}

export class HomePageSundayRun {
    constructor(protected pageBusyIndicator: PageBusyIndicator) {
        this.GetSundayRunInfo().then(async (sr) => {
            await this.DrawSundayRun(sr);
            this.pageBusyIndicator.Decrement()
        })
    }

    private async GetSundayRunInfo(): Promise<SundayRun> {
        let URL = '/BTCAPI/SundayRuns/GetSundayRunHomePageInfo';
        return await jQuery.get(URL, (json: SundayRun) => json);
    }

    private async DrawSundayRun(sundayRun: SundayRun): Promise<void> {
        if (sundayRun.isPrivate && !(await jQuery.get('/BTCAPI/Members/IsUserActive') as boolean)) {
            (document.getElementById("divPrivateSundayRunMsg") as HTMLDivElement).style.display = "block";
        }
        else {
            (document.getElementById("spanSundayRunCourseTitle") as HTMLSpanElement).innerText = sundayRun.runTitle;

            let DateTimeMsg = sundayRun.strTargetDate + ", " + sundayRun.startTime;
            // If workout is past, then append a message
            var dt = new Date();
            var today = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
            let PlannedDate = new Date(sundayRun.targetDate);
            if (PlannedDate < today) // Sunday run is past?
                DateTimeMsg += ". <i><small>(This workout is concluded. Check back later for next Sunday's info)</small></i>";
            (document.getElementById("spanSundayRunDateTime") as HTMLSpanElement).innerHTML = DateTimeMsg;

            let CourseInfo = '';
            if (sundayRun.locationMapURL.length !== 0)
                CourseInfo = '<a href="' + sundayRun.locationMapURL + '" target="_blank">' + sundayRun.locationName + '</a>';
            else
                CourseInfo = sundayRun.locationName;
            if (sundayRun.courseMapURL.length !== 0)
                CourseInfo += ' (' + '<a target="_blank" href=' + sundayRun.courseMapURL + ">Course Map</a>)";
            if (CourseInfo.length !== 0)
                (document.getElementById("spanCourse") as HTMLSpanElement).innerHTML = CourseInfo;
            else
                (document.getElementById("divSundayRunCourseInfo") as HTMLDivElement).style.display = 'none';

            (document.getElementById("spanSundayRunMiles") as HTMLSpanElement).innerText = sundayRun.miles + "mi";

            if (sundayRun.miscNotes.length !== 0)
                (document.getElementById("spanSundayRunMiscNotes") as HTMLSpanElement).innerHTML = sundayRun.miscNotes;
            else
                (document.getElementById("divSundayRunMiscNotes") as HTMLDivElement).style.display = "none";


            let DrinksSupportDesc = '';
            switch (sundayRun.drinkSupportMethod) {
                case 'Personal': DrinksSupportDesc = "Your personal bottles will be transported."; break;
                case 'Supplied': DrinksSupportDesc = "Drinks will be provided by BTC (leave your bottle in your car)."; break;
                default: DrinksSupportDesc = sundayRun.drinkSupportMethod + ".";
            }
            if (sundayRun.drinkNotes.length !== 0)
                DrinksSupportDesc += " " + sundayRun.drinkNotes;
            (document.getElementById("spanSundayRunDrinksSupport") as HTMLSpanElement).innerHTML = DrinksSupportDesc;

            if (sundayRun.courseDescription.length !== 0)
                (document.getElementById("spanSundayRunCourseDesc") as HTMLSpanElement).innerHTML = sundayRun.courseDescription;
            else
                (document.getElementById("divSundayRunCourseDesc") as HTMLDivElement).style.display = "none";

            (document.getElementById("divHomeSundayInfo") as HTMLDivElement).style.display = "block";
        }
    }
}
