import jQuery from "jquery";
import { EditRecordBase } from "../Lib/EditRecordBase";
import { ReportErrorToServer } from "../Lib/ReportErrorToServer";

export class EditEventForm extends EditRecordBase {
    constructor(protected readonly FormId: string) {
        super(FormId, '/BTCAPI/Events', '/admin/Events');
        this.GetDataFromServer();
    }

    private async GetDataFromServer(): Promise<void> {
        if (this.RecordId > 0) {
            const getURL: string = '/BTCAPI/Events/' + this.RecordId;
            await jQuery.get(getURL,
                (JsonData: { title: string, eventDate: string, eventTime: string, description: string }) => {
                    (document.getElementById('Title') as HTMLInputElement).value = JsonData.title;
                    (document.getElementById('EventDate') as HTMLInputElement).value = JsonData.eventDate;
                    (document.getElementById('EventTime') as HTMLInputElement).value = JsonData.eventTime;
                    (document.getElementById('Description') as HTMLInputElement).innerText = JsonData.description;
                })
                .fail(async (jqXHR, textStatus, errorThrown) => {
                    await ReportErrorToServer.DisplayAndProcessAjaxFailure(getURL, "GET", jqXHR, textStatus, errorThrown, null, null)
                });
        }
    }
}
