import { ReportsBaseClass } from "../Lib/ReportsBaseClass"

export class MyRaceResults extends ReportsBaseClass {
    constructor() {
        super('tableMyRaceResults', '/BTCAPI/Members/GetMyRaceResults');
        this.GetServerData();
    }

    ConvertRecordToTR(row: JQuery<HTMLTableRowElement>, record: {
        resultId: number,
        eventDate: string,
        eventName: string,
        city: string,
        dist: string,
        time: string,
        pace: string,
        genderPlace: string,
        divPlace: string,
        pr: string,
        comment: string
    }) {
        row.find('.spanDate').html(`<a href='/btc-news/edit-race-results?RecordId=${record.resultId}'/>${record.eventDate}</a>`);
        row.find('.spanEvent').html(record.eventName);
        row.find('.spanCity').html(record.city);
        row.find('.spanDist').html(record.dist);
        row.find('.spanTime').html(record.time);
        row.find('.spanPace').html(record.pace);
        row.find('.spanGenderPlace').html(record.genderPlace);
        row.find('.spanDivPlace').html(record.divPlace);

        if (!record.pr) // Not a PR?
            row.find('.PRCheckMark').css("display", "none");

        row.find('.spanComment').html(record.comment);
    }
}
